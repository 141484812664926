import React from 'react';
import { getBotNotRunningMessage, getLoginMessage, getInstancesMessage } from "./messages";
import { isDefined } from "../../helpers";

export function getSteps() {
    return ['Online-Check', 'Authentication', 'Login-Mask', 'Instance-Selection', 'Enabling Webstream', 'Synchronizing'];
}

export function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Step 1: Checking bot connectivity';
        case 1:
            return 'Step 2: Authenticating  or consuming URL Parameters';
        case 2:
            return 'Step 3: Providing login mask';
        case 3:
            return 'Step 4: Providing instance selection';
        case 4:
            return 'Step 5: Fetching webstream url';
        case 5:
            return 'Step 6: Activating synchronization to sinusbot!';
        default:
            return 'Unknown step';
    }
}

export function handleStepperIncrease(callback, status) {
    if (status === false)
        this.handleStepperFail();
    else {
        this.setState(state => ({
            authSteps: {
                ...state.authSteps,
                current: state.authSteps.current + 1,
                failed: -1
            }
        }), callback);
    }
}

export function handleStepperFail(callback) {
    let currentStep = this.state.authSteps.current;
    console.error("Stepper fails at index", currentStep, "(", this.getStepContent(currentStep), ")");

    this.setState(state => ({
        authSteps: {
            ...state.authSteps,
            failed: currentStep
        }
    }), () => callback);
}

export function handleStepperNext() {
    this.handleStepperStep(this.state.authSteps.current);
}

export function handleComplete(callback) {

    // eslint-disable-next-line react/no-access-state-in-setstate
    const completed = new Set(this.state.authSteps.completed);
    completed.add(this.state.authSteps.activeStep);
    this.setState(state => ({
        authSteps: {
            ...state.authSteps,
            completed: completed
        }
    }), () => callback);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== this.totalSteps() - this.skippedSteps()) {
        this.handleNext();
    }
};

export function handleStepperStep(step) {
    const me = this;
    // console.debug("Handling step", step);

    // switch on current step
    switch (step) {
        case 0:
            // me.getFromUrlStoreAndState(null, "botId", (id) => {
            //     if (!isDefined(id)) {
            //         // console.debug("No botId in storage: trying to fetch");
            //         me.props.enqueueSnackbar("No cache-data available. Trying to fetch information from sinusbot...", {variant: 'info'});

                    // fetch default botId or show error
                    me.fetchDefaultBotId(status => {
                        if (status === false) {
                            me.props.enqueueSnackbar("Could not connect to sinusbot!", {variant: 'error'});
                            // console.debug("No bot for login found: showing error. status:", status);
                            this.setMessage(getBotNotRunningMessage(), () => {
                                me.handleStepperFail();
                            });
                        } else {
                            me.handleStepperIncrease(me.handleStepperNext, status);
                        }
                    });
            //     } else {
            //         me.handleStepperIncrease(me.handleStepperNext, id);
            //     }
            // });
            break;
        case 1:
            // authenticate headers from cache or url 
            // or consume url parameters
            me.authenticate(status => {
                if (!isDefined(status)) {
                    // me.props.enqueueSnackbar("Could not automatically sign you in!", {
                    //     variant: 'warning'
                    // });

                    me.handleStepperSkip(me.handleStepperNext);
                } else {
                    me.handleStepperIncrease(me.handleStepperNext, status);
                }
            });
            break;
        case 2:
            if (!me.hasToken()) {
                me.props.enqueueSnackbar("Please sign in!", {
                    variant: 'warning'
                });
                // console.error("No token found from auto-login!");
                // show login mask
                let msg = getLoginMessage({
                    ...me.state,
                    handleFormLogin: me.handleFormLogin,
                    handleUserStateChange: me.handleUserStateChange,
                    handleStepperNext: me.handleStepperNext,
                    setMessage: me.setMessage,
                    fetchInstanceInfos: me.fetchInstanceInfos,
                    onStore: me.storeAndState
                });
                me.setMessage(msg, () => {
                    me.handleStepperFail();
                });
            } else {
                // skip login screen
                // me.props.enqueueSnackbar("You were automatically signed in from a cached token!");
                me.handleStepperIncrease(me.handleStepperNext, true);
            }
            break;
        case 3:
            me.getFromUrlStoreAndState(null, "instance", (id) => {
                if (!isDefined(id)) {
                    // catch instance selection errors
                    // console.warn("No instance found: showing instance selection");
                    me.props.enqueueSnackbar("No instance found in cache: Showing instance selection", {
                        variant: 'warning'
                    });
                    
                    // fetch infos about all instances
                    me.fetchInstanceInfos(status => {
                        me.setMessage(getInstancesMessage({
                            ...me.state,
                            chooseInstance: me.chooseInstance,
                            handleUserStateChange: me.handleUserStateChange,
                            onStore: me.storeAndState
                        }));
                    });
                } else {
                    // skip instance selection
                    me.handleStepperIncrease(me.handleStepperNext, true);
                }
            })
            break;
        case 4:
            me.getStreamToken((data) => {
                if (!isDefined(data) || !isDefined(data["token"])) {

                    this.setMessage(getBotNotRunningMessage(), () => {
                        me.logout();
                    });

                    return;
                }

                let streamtoken = data.token;

                me.storeAndState("streamToken", streamtoken, () => {                    
                    let streamUrl = '/api/v1/b/bot/i/' + me.state.user.instance + '/stream/' + streamtoken;

                    me.storeAndState("streamUrl", streamUrl, () => {
                        // me.props.enqueueSnackbar("Cached audiostream url for later usage.", {
                        //     variant: 'info'
                        // });
                        console.debug("Cached audiostream url for later usage.");
                        me.handleStepperIncrease(me.handleStepperNext, true);
                    });
                });
            });
            break;
        case 5:
            me.setMessage({});
            // console.debug("Stepper completed!");
            me.props.enqueueSnackbar((
                <div>
                    Connected to {this.state.user.instance}.
                </div>
            ), {
                variant: 'success'
            });

            me.toggleSync(true);
            break;
        default:
            break;
    }
}

export function totalSteps() {
    return this.getSteps().length;
}


export function skippedSteps() {
    return this.state.authSteps.skipped.size;
}


export function completedSteps() {
    return this.state.authSteps.completed.size;
}

export function handleStepperBack(callback) {
    this.setState(state => ({
        authSteps: {
            ...state.authSteps,
            current: state.authSteps.current - 1
        }
    }), () => callback);
}

export function handleStepperReset(callback) {
    this.setState({
        authSteps: {
            current: 0,
            failed: -1,
            skipped: new Set(),
        }
    }, () => {
        if(isDefined(callback))
            callback();
    });
}

export function handleStepperSkip(callback) {
    const { authSteps } = this.state;
    let { current } = authSteps;
    console.debug("-> Skipping step", current);

    if (!this.isStepperStepOptional(current)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
        const skippedVal = new Set(state.authSteps.skipped.values());
        skippedVal.add(current);
        return {
            authSteps: {
                ...state.authSteps,
                current: current + 1,
                skipped: skippedVal,
            }
        };
    }, () => {
        if(isDefined(callback)) {
            callback();
        }
    });
}

export function isStepperStepOptional(step) {
    return step === 1 || step === 2 || step === 3;
}

export function isStepperStepFailed(step) {
    return step === this.state.authSteps.failed
}

export function isStepperStepSkipped(step) {
    return this.state.authSteps.skipped.has(step);
}

export function showStepInstanceSelection () {
    this.removeSetting("instance");
    let userTmp = {
        ...this.state.user
    };
    delete userTmp["instance"];

    // this.props.enqueueSnackbar("Showing Instance selection");
    // console.log("showing instance selection");

    this.setState({
        user: userTmp
    }, () => {
        this.setState(state => ({
            authSteps: {
                ...state.authSteps,
                current: 3,
                failed: -1
            }
        }), this.handleStepperNext);
    });
}

