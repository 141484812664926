import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import GMap from './gMap';
import Elevations from './elevations';
import store from 'store';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
// import { Home } from '@material-ui/icons';
// import FormLabel from '@material-ui/core/FormLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
    root: {
        // padding: theme.spacing(2)
    },
    map: {
        height: 350,
        width: '100%',
        position: 'relative',
    },
    form: {
        padding: theme.spacing(1) + 'px 0'
    },
    searchField: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        margin: theme.spacing(1) + 'px auto'
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },

    // formControl: {
    //     margin: theme.spacing(3),
    // },
});

export function CustomizedInputBase(props) {
    const { classes } = props;

    return (
        <Paper className={classes.searchField} elevation={1}>
            {/* <IconButton className={classes.iconButton} aria-label="Menu">
                <MenuIcon />
            </IconButton> */}
            <InputBase className={classes.input} placeholder="Search Google Maps" />
            <IconButton className={classes.iconButton} aria-label="Search">
                <SearchIcon />
            </IconButton>
            <Divider className={classes.divider} />
            <IconButton color="primary" className={classes.iconButton} aria-label="Directions">
                <DirectionsIcon />
            </IconButton>
        </Paper>
    );
}

CustomizedInputBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(CustomizedInputBase);


//40.854885
//-88.081807
class ElevationFinder extends React.Component {
    state = {
        query: '',
        lastUrl: '',
        results: {},
        center: {
            lat: (store.get('center.lat') != null ? store.get('center.lat') : '47.6683504'),
            lng: (store.get('center.lng') != null ? store.get('center.lng') : '11.1436098'),
        },
        zoom: (store.get('zoom') != null ? store.get('zoom') : 13),
        bounds: {
            topLeft: {
                lat: '39.7391536',
                lng: '-104.9847034'
            },
            botRight: {
                lat: '39.7391536',
                lng: '-104.9847034'
            }
        }
    };

    /* event handler */
    handleSearchSubmit = (event) => {
        //alert('A name was submitted: ' + this.props.bounds);
        event.preventDefault();
    }

    changeTopLeftLat = (event) => {
        console.log("topleft changed", event);
        console.log("topleft changed: props", this.props);

        let bounds = {...this.props.bounds};
        bounds.topLeft.lat = event.target.value;
        this.setState({bounds: bounds});
    }

    changeTopLeftLng = (event) => {
        let bounds = {...this.props.bounds};
        bounds.topLeft.lng = event.target.value;
        this.setState({bounds: bounds});
    }

    changeBotRightLat = (event) => {
        let bounds = {...this.props.bounds};
        bounds.botRight.lat = event.target.value;
        this.setState({bounds: bounds});
    }

    changeBotRightLng = (event) => {
        let bounds = {...this.props.bounds};
        bounds.botRight.lng = event.target.value;
        this.setState({bounds: bounds});
    }

    queried = (phrase) => {
        this.setState({
            query: phrase
        });
        store.set('query', phrase);
    }

    mapChanged = (map) => {
        // console.log(map);
        this.setState({
            center: map.center,
            zoom: map.zoom
        });
        store.set('center.lat', map.center.lat);
        store.set('center.lng', map.center.lng);
        store.set('zoom', map.zoom);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                <div className={classes.map}>
                    <GMap
                        center={{
                            lat: this.state.center.lat,
                            lng: this.state.center.lng
                        }}
                        zoom={this.state.zoom}
                        onChange={this.mapChanged}
                        >
                    </GMap> 
                </div>

                <form className={classes.form} onSubmit={this.handleSearchSubmit} noValidate autoComplete="off">
                    <CustomizedInputBase classes={classes} />
                    
                    {/* <FormControl component="fieldset" className={classes.formControl}> */}
                    {/* <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.checkedA}
                                onChange={this.handleChange('checkedA')}
                                value="checkedA"
                            />
                        }
                        label="Secondary"
                    /> */}
                    {/* <FormLabel component="legend">Current</FormLabel> */}
                    {/* <FormGroup row>
                        <TextField
                            id="query"
                            label="Einen Ort suchen"
                            value={this.state.query}
                            margin="none"
                        />
                        <Button variant="extendedFab" color="primary" type="submit"><Home /> Fetch elevation</Button>
                    </FormGroup>
                    </FormControl> */}
                    {/* <input type="submit" value="Submit" /> */}
                </form>

                <Elevations {...this.state} />
            </div>
        )
    }
}

ElevationFinder.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default App;
export default withStyles(styles)(ElevationFinder);
