import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// eslint-disable-next-line
import { red, green, grey, blue, yellow, orange, purple } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';

import './sass/_fonts.sass';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    palette: {
        background: {
            default: '#ccc'
        },
        type: 'dark',
        primary: {
            light: '#EEEEEE',
            main: '#181818',
            dark: '#111111',
        },
        secondary: {
            light: yellow[300],
            main: yellow[500],
            dark: green[700],
        },
        text: {
            primary: "#aaa",
            secondary: "#eee"
        },

    },
    typography: {
        useNextVariants: true,
        fontFamily: 
            '-apple-system,system-ui,BlinkMacSystemFont,' +
            '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    },
});


function withRoot(Component) {
    function WithRoot(props) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <MuiThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...props} />
            </MuiThemeProvider>
        );
    }
    return WithRoot;
}

export default withRoot;
