import React from 'react';
import ReactPlayer from 'react-player'
//import { ErrorBoundary } from '../boundaries.jsx';
import BgImg from './img/tv-2.gif';

const styles = {
    position: 'absolute',
    top: 0, 
    left: 0, 
    right: 0, 
    bottom: 0,
    background: 'url(' + BgImg + ') no-repeat center top / cover'
    // height: 'calc(100vh - ' + theme.spacing(14) + 'px)',
};

class MediaPlayer extends React.Component 
{
    constructor(props) {
        super(props);
        this.playerInstance = React.createRef();
    }

    render() 
    {
        const { onPlayerError, onPlayerCheck, onDuration } = this.props;
        let { medium, controls } = this.props;
        let { file } = medium;

        return (
            <div style={styles}>
                <ReactPlayer 
                    ref={this.playerInstance}
                    url={file} 
                    playing={true} 
                    controls={controls}
                    loop={false} 
                    height={'100%'} 
                    width={'100%'} 
                    volume={0.3}
                    muted={false}
                    config={{
                        youtube: {
                            playerVars: {
                                autoplay: 1,
                                controls: 1,
                                disablekb: 1,
                                iv_load_policy: 3,
                                loop: 0,
                                modestbranding: 1,
                                rel: 0,
                                showinfo: 0,
                                start: Math.ceil(medium.start / 1000) + 1 || 0
                            }
                        },
                        file: {
                            forceAudio: true
                        }

                    }}
                    onError={onPlayerError} 
                    onPlay={onPlayerCheck}
                    onDuration={onDuration}
                />
            </div>
        );
    }
}

export default MediaPlayer;
