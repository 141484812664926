import React, { Component, Fragment } from 'react';
import { Link as RLink, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Login from './login';
import GamesInCommon from './friends/gamesInCommon';
import HumbleZone from './humble/index';
import InventoryValue from './friends/inventoryValue';
import { getProxyUrl, isDefined } from '../../helpers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import SteamBgImg from './img/background-2.jpg';
import { getTabValue } from './functions';
import { STEAM_API } from '../../db/schemasSteam';
import Axios from 'axios';
import { withSnackbar } from 'notistack';

function TabContainer(props) {
  return (
    <Typography component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
    '@global': {
        body: {
            background: '#1B2838 url(' + SteamBgImg +') no-repeat top center fixed / cover',
        }
    }
});

const steamTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            light: '#EEEEEE',
            main: '#1B2838',
            dark: '#111111',
        },
        secondary: {
            light: '#EEEEEE',
            main: '#4D96B2',
            dark: '#778811',
        },
    },
    text: {
        primary: "#ffffff",
        secondary: "#eee"
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Roboto'
    },
});


class SteamTools extends Component 
{
    state = {
        lastUrl: '',
        results: {},
        urls: {
            base: getProxyUrl() + "r3st/steam/",
            login: 'http://steamcommunity.com/openid',
            return: getProxyUrl() + 'r3st/steamlogin'
        },
        selectedTab: 0,
    };

    handleTabClick = (event, value) => {
        this.setState({selectedTab: value});
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }


    handleFetchProfile = () => {
        const { enqueueSnackbar, handleRootState, user } = this.props;
        let { openId } = user;
        
        this.fetchProfileData(openId, (data) => {
            if(data == null) {
                enqueueSnackbar("Could not fetch profile.", { variant: 'error' });
                return;
            }

            handleRootState({user: { ...user, summary: data }});
            //this.setState({ user:  });
            enqueueSnackbar("Profile fetched.", { variant: 'success' });
        });
    }

    fetchAllFriendsData = (steamId, callback) => {
        console.log("getting all friends of " + steamId);

        let friendUrl = STEAM_API.FRIENDS.url(this.state.urls.base, steamId);

        fetch(friendUrl)
            .then(response => response.json())
            .then((data) => {
                callback(data);
            })
            .catch((reason) => {
                let msg = "Could not retrieve steam data!";
                console.error(msg);
                // enqueueSnackbar(msg, {
                //     variant: 'error'
                // });
                callback();
            });
    }

    handleRefreshFriends = () => {
        const { enqueueSnackbar } = this.props;
        const { openId } = this.state;
        let dies = this;

        // request all friends of friends from steam so they land in db
        this.fetchAllFriendsData(openId, (friends) => {
            if (friends == null) {
                enqueueSnackbar("Cannot save your friends.", { variant: 'error' });
                return;
            }

            enqueueSnackbar("Your friends should now be Users in DB.", { variant: 'success' });

            dies.setUserByOpenidDB();
        });
    }

    handleRefreshProfilesOfFriends = () => {
        this.doForAllFriends(STEAM_API.PROFILE, () => {
            this.setUserByOpenidDB();
        });
    }

    handleRefreshFriendsOfFriends = () => {
        this.doForAllFriends(STEAM_API.FRIENDS, () => {
            this.setUserByOpenidDB();
        });
    }

    handleRefreshGames = () => {
        this.doForAllFriends(STEAM_API.GAMES, () => {
            this.setUserByOpenidDB();
        });
    }

    doForAllFriends = (API_URL, callback) => {
        const { enqueueSnackbar } = this.props;
        const { user } = this.state;
        const { friends } = user;

        // request all friends from steam so they land in db
        let dies = this;
        let allTasks = [];
        friends.forEach((item, index) => {
            let urlToResolve = API_URL.url(dies.state.urls.base, item.openId);
            allTasks.push(urlToResolve); // Axios.get(Friendurl)
        });

        dies.doTimedOutProxyTasks(allTasks).then(() => {
            enqueueSnackbar("Done with all requests!", { variant: 'success' });
            callback();
        });
    }


    doTimedOutProxyTasks = async (tasks) => {
        const { enqueueSnackbar } = this.props;
        let starterPromise = Promise.resolve(null);
        await tasks.reduce(
            (promise, urlToProxy) => promise.then(() => Axios.get(urlToProxy).then((data) => new Promise(resolve => {
                let msg = "updating " + urlToProxy;
                console.log(msg, data);
                enqueueSnackbar(msg);
                setTimeout(resolve, 500);
            })).catch((reason) => {
                enqueueSnackbar("skipping promise", { variant: 'warning' });
                console.error("skipping promise");
            })),
            starterPromise
        );
    }


    fetchProfileData = (steamId, callback) => {
        const { enqueueSnackbar } = this.props;
        console.log("--> Getting profile of " + steamId);

        let apiProfileUrl = STEAM_API.PROFILE.url(this.state.urls.base, steamId);

        fetch(apiProfileUrl)
        .then(response => response.json())
        .then((data) => {
            let msg = "Retrieved profile data!";
            console.log(msg);
            callback(data);
        })
        .catch((reason) => {
            let msg = "Could not retrieve steam data!";
            console.error(msg);
            enqueueSnackbar(msg, {
                variant: 'error'
            });
            callback();
        });
    }

    isSignedIn = () => {
        const { session } = this.props;
        let isIt = isDefined(session) && session !== "{}" && isDefined(session.openId);
        // if(!isIt)
        //     console.log("Not signed in!");
        return isIt;
    }

    getOpenId() {
        return this.isSignedIn() ? this.props.user.openId : null;
    }

    setUserByOpenidDB = (openId) => {
        const { enqueueSnackbar, handleRootState } = this.props;

        if(!isDefined(openId)) {
            openId = this.props.openId;
        }

        fetch('/r3st/openid/' + openId)
        .then(response => response.json())
        .then((data) => {
            console.log("we got back", data);
            // fetch all friend profiles TODO
            // var allTasks = [];
            // data.friends.forEach((item, index) => {
            //     let profileUrl = API_URLS.getProfile.url(this.state.urls.base, item.steamid);
            //     allTasks.push(profileUrl); // Axios.get(Friendurl)
            // });
            // this.doTimedOutProxyTasks(allTasks).then((res) => {
            //     enqueueSnackbar("Done with user information", { variant: 'success' });
            //     console.log(res);
            handleRootState({ user: { ...data } });
            // });
        })
        .catch((reason) => {
            enqueueSnackbar("Could not retrieve friends due to ['" + reason + "']!", {
                variant: 'error'
            });
        });
    }

    componentDidUpdate() {
        if (isDefined(this.props.openId) && !isDefined(this.props.user.countFriends))
            this.setUserByOpenidDB();
    }


    render() {
        // const { classes } = this.props;
        let signedIn = this.isSignedIn();

        return (
            <MuiThemeProvider theme={steamTheme}>

                <Route
                    path="/"
                    render={({ location }) => (
                        <Fragment>
                            <AppBar position="static">
                                <Tabs value={getTabValue(location, 2)}>
                                    <Tab label="Profil" value="" component={RLink} to="/steam" />
                                    <Tab label="HumbleZone" value="humble" component={RLink} to="/steam/humble" />
                                    <Tab disabled={!signedIn} label="Importer" value="importer" component={RLink} to="/steam/importer" />}
                                    <Tab disabled={!signedIn} label="Inventory Value" value="inventory" component={RLink} to="/steam/inventory" />}
                                </Tabs>
                            </AppBar>

                            <Switch>
                                <Route path="/steam" exact={true} render={() => (
                                    <Login {... this.state} {... this.props} signedIn={signedIn} />
                                )} />
                                <Route path="/steam/humble" exact={true} render={() => (
                                    <HumbleZone isSignedIn={signedIn} {... this.state} {... this.props} />
                                )} />
                                <Route path="/steam/importer" exact={true} render={() => (
                                    signedIn && <GamesInCommon 
                                        {...this.state} 
                                        {...this.props} 
                                        handleFetchProfile={this.handleFetchProfile} 
                                        handleRefreshFriends={this.handleRefreshFriends}
                                        handleRefreshFriendsOfFriends={this.handleRefreshFriendsOfFriends}
                                        handleRefreshGames={this.handleRefreshGames}
                                        handleRefreshProfilesOfFriends={this.handleRefreshProfilesOfFriends}
                                    />
                                )} />
                                <Route path="/steam/inventory" exact={true} render={() => (
                                    signedIn && <InventoryValue {... this.state} {... this.props} />
                                )} />
                            </Switch>
                        </Fragment>
                    )}
                />
            </MuiThemeProvider>
        )
    }
}

SteamTools.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(SteamTools));
