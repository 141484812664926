import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';

const styles = theme => ({
    root: {
        // padding: theme.spacing(2) + 'px 0',
    },
    results: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    marged: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    flexContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },

});

export class ElevationInfos extends Component 
{
    handleSubmit(event) {
        alert('A name was submitted: ' + this.props.bounds);
        event.preventDefault();
    }

    fetchResult(url) {
        console.log("checking "+url);
    }

    getElevationUrl(lat, lng) {
        let elevationUrl = 'https://maps.googleapis.com/maps/api/elevation/json?locations='+lat+','+lng+'&key='+process.env.REACT_APP_GOOGLE_API_KEY;
        return elevationUrl;
    }

    render() {
        const { classes } = this.props;

        let lat = this.props.center.lat;
        let lng = this.props.center.lng;
        let zoom = this.props.zoom;
        let eleUrl = this.getElevationUrl(lat, lng);


        return (
            <div className={classes.root}>
                <Paper className={classes.marged} elevation={0}>
                    <form className={classes.flexContainer} onSubmit={this.handleSubmit} noValidate autoComplete="off">        
                        <TextField
                            id="zoom"
                            label="Zoom"
                            value={zoom}
                            margin="normal"
                            className={classes.textField}
                        />              
                        <TextField
                            id="from-lat"
                            label="Latitude"
                            value={this.props.bounds.topLeft.lat}
                            onChange={this.props.changeTopLeftLat}
                            margin="normal"
                            className={classes.textField}
                        />
                        <TextField
                            id="from-lng"
                            label="Longitude"
                            value={this.props.bounds.topLeft.lng}
                            onChange={this.props.changeTopLeftLng}
                            margin="normal"
                            className={classes.textField}
                        />
                        <TextField
                            id="to-lat"
                            label="Longitude"
                            value={this.props.bounds.botRight.lat}
                            onChange={this.props.changeBotRightLat}
                            margin="normal"
                            className={classes.textField}
                        />
                        <TextField
                            id="to-lng"
                            label="Longitude"
                            value={this.props.bounds.botRight.lng}
                            onChange={this.props.changeBotRightLng}
                            margin="normal"
                            className={classes.textField}
                        />
                    </form>

                    <Button variant="contained" color="primary" type="submit">Abschicken</Button>
                </Paper>

                <Paper className={classes.marged} elevation={0}>
                    <Typography variant="subtitle2">The Google Maps URL is:</Typography>
                    <Typography component="p"><Link href={eleUrl}>{eleUrl}</Link></Typography>
                </Paper>

                {this.props.results.length > 0 && (
                    <Paper className={classes.results} elevation={0}>
                        <Typography variant="subtitle2">The Results are:</Typography>
                        {JSON.stringify(this.props.results)}
                    </Paper>
                )}

            </div>
        )
    }
}

export default withStyles(styles)(ElevationInfos);
