import React from 'react';
import { isDefined } from '../../helpers';
import PropTypes from 'prop-types';
import { TextField, FormControl, Button } from '@material-ui/core';
import { ErrorBoundary } from '../boundaries.jsx';
import { withSnackbar } from 'notistack';

class FormLoginClass extends React.Component 
{
    render() 
    {
        let { user, handleUserStateChange, handleFormLogin } = this.props;
        let { botId, username } = user;
        let hasBotId = isDefined(botId);

        return (
            <ErrorBoundary>
                <form onSubmit={handleFormLogin}>
                    <FormControl variant="filled">
                        <TextField
                            name="username"
                            label="Username"
                            // value={username}
                            onChange={handleUserStateChange}
                        />              
                        <TextField
                            name="password"
                            label="Password"
                            // value={password}
                            onChange={handleUserStateChange}
                        />
                        <Button 
                            variant="contained" 
                            color="primary" 
                            title={"Login to " + botId + " as " + username} 
                            disabled={!hasBotId} 
                            type="submit"
                            onClick={handleFormLogin}
                        >
                            Abschicken
                        </Button>
                    </FormControl>
                </form>
            </ErrorBoundary >
        )
    }
}

FormLoginClass.propTypes = {
    user: PropTypes.object.isRequired,
    handleUserStateChange: PropTypes.func.isRequired
};

export const FormLogin = withSnackbar(FormLoginClass);