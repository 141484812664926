import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';

//import RainEffect from 'rain-effect';

const styles = theme => ({
});

const version = "0.2";


class RainTime extends React.Component {
    state = {
        weather: '',
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root} elevation={1}>
                <Paper className={classes.inner} elevation={1}>
                    <Typography variant="h4" gutterBottom>RainTime {version}</Typography>
                    {/* <RainEffect /> */}
                </Paper>
            </div>
        )
    }
}

RainTime.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default App;
export default withSnackbar(withStyles(styles)(RainTime));

