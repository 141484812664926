import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// import WebFont from 'webfontloader';

//import Index from './pages/index-syncwatch';
import Index from './pages/index';

// WebFont.load({custom: {
//     families: ['Roboto:500', 'sans-serif'],
//     urls: ['./fonts/roboto.woff2']
// }});
//     google: {
//         families: ['Roboto:500', 'sans-serif']
//     }
// });


ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
