import { isDefined, parseJson, inObjDefined, mergeObjectsById } from '../helpers';
import https from 'https';
//import http from 'http';
import Async from 'async';

const mongoose = require('mongoose');
const Schema = mongoose.Schema;



// Config
export const API_CONFIG = {
    KEY: 'BC2553CB3D6DB1EB1D8A0F5EBFD35E1A',
    APPID: '8930',
}

export const STEAM_API = {
    // Returns the friend list of any Steam user, provided their Steam Community profile visibility is set to "Public".
    // Example URL: http://api.steampowered.com/ISteamUser/GetFriendList/v0001/?key=XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX&steamid=76561197960435530&relationship=friend
    FRIENDS: {
        url: (baseUrl, openId, apiKey = null) => baseUrl + 'ISteamUser/GetFriendList/v0001/?key=' + apiKey + '&steamid=' + openId + '&relationship=friend',
        regex: /\/ISteamUser\/GetFriendList\/.*&steamid=([^&]+)/i
    },
    // Example URL: http://api.steampowered.com/ISteamUser/GetPlayerSummaries/v0002/?key=XXXXXXXXXXXXXXXXXXXXXXX&steamids=76561197960435530 (This will show Robin Walker's profile information.)
    // Returns basic profile information for a list of 64 - bit Steam IDs.
    PROFILE: {
        url: (baseUrl, openId, apiKey = null) => baseUrl + 'ISteamUser/GetPlayerSummaries/v0002/?key=' + apiKey + '&steamids=[' + openId + ']',
        regex: /ISteamUser\/GetPlayerSummaries\/.*&steamids=\[(.*?)\]/i
    },
    // GetOwnedGames returns a list of games a player owns along with some playtime information, if the profile is publicly visible.Private, friends - only, and other privacy settings are not supported unless you are asking for your own personal details(ie the WebAPI key you are using is linked to the steamid you are requesting).
    // Example URL: http://api.steampowered.com/IPlayerService/GetOwnedGames/v0001/?key=XXXXXXXXXXXXXXXXX&steamid=76561197960434622&format=json
    GAMES: {
        url: (baseUrl, openId, apiKey = null) => baseUrl + 'IPlayerService/GetOwnedGames/v0001/?key=' + apiKey + '&steamid=' + openId + '&format=json',
        regex: /IPlayerService\/GetOwnedGames\/.*&steamid=(\d+)/i
    }
}


// Login DB Model/Schema
export const schemaUser = new Schema({
    name: String,
    openId: String,
    lastlogin: String,
    summary: Object,
    friends: Array,
    games: Object,
});

export const User = mongoose.model('User', schemaUser);



export function getAppBaseUrl() {
    return (process.env.NODE_ENV === "production" ? 'https://' : 'http://') + (process.env.HOST || 'localhost') + ':' + (process.env.PORT || 3000);
}

export async function fetchRandomUserFromDB() {
    return new Promise((resolve, reject) => {
        try {
            User
            .countDocuments()
            .exec(function (err, count) 
            {
                // Get a random entry
                var random = Math.floor(Math.random() * count);

                // Again query all users but only fetch one offset by our random #
                User
                .findOne()
                .skip(random)
                .exec(
                function (err, result) 
                {
                    // catch db errors
                    if(isDefined(err)) {
                        reject(err);
                        return;
                    }

                    // maybe no users in db
                    if(!isDefined(result)) {
                        reject(err);
                        return;
                    }

                    let openId = result["openId"];

                    // Tada! random user
                    console.log("returning user #", random, "with steamid", openId);
                    resolve(result);
                });
            })
        } catch (error) {
            reject(error);
        }
    });
}


// Sample Data
export function getSampleData(request, response, next) {
    let type = request.params.type;
    console.log("getSampleData: the params are", request.params);

    switch(type) {
        default: break;

        // find random user and return its json
        case "user":
            // Get the count of all users
            fetchRandomUserFromDB() // fetch a random user from db
            .then((user, err) => {
                if(isDefined(err)) {
                    console.log("err", err);
                    return;
                }

                let openId = user.openId;

                fetchFriendProfilesFromDB(openId, "summary", "summary.personaname")
                .then((friendProfiles, err) => {
                    // add each friends profile to the current user json
                    let friendData = user.friends;
                    Array.prototype.push.apply(friendData, friendProfiles);
                    let arr = [...user.friends, friendData];
                    //friendData = arr.sort(compareSummary);
                    user.friends = arr;
                    response.json(user);
                });
            });

            // User
            // .aggregate([{ $sample: { size: 1 } }])
            // .findOne(function (err1, user) {
            //     if (err1)
            //         return response.send(err1)
            //     console.log(user);                
            // });
            break;
    }
}

export async function fetchFriendProfilesFromDB(openId, unwindField, sortField) 
{
    return new Promise((resolve, reject) => {
        User.aggregate([
            {
                $project: {
                    friends: {
            //             $elemMatch: {
            //                 openId: openId,
            //                 // $sort: {
            //                 //     "summary.personaname": -1
            //                 // }
            //             },
                        $filter: {
                            input: "$friends",
                            as: "item",
                            cond: {
                                "$eq": [
                                    "$$item.openId", openId
                                ]
                            }
                        }
                    }
                }
            },
            { $unwind: "$" + unwindField },
            { $sort: { [sortField]: 1 } },
            // {
            //     $match: {
            //         friends: {
            //             $elemMatch: {
            //                 openId: openId,
            //             },
            //         }
            //     }
            // },
            // { $group: { openId: "$openId", summary: { $push: "$summary" } } }
            //{ $merge: { into: "myOutput", on: "_id", whenMatched: "replace", whenNotMatched: "insert" } }
        ])
        .exec((err, sortedFriends) => {
            if (isDefined(err)) {
                console.log("err", err);
                reject(err);
            }

            console.log("returning", sortedFriends)
            resolve(sortedFriends);
        });
    });
}

// Login
export function steamLogin(request, response) {
    let steamIdString = request.query["openid.claimed_id"];
    if (typeof steamIdString === "undefined") {
        console.log("not signed in correctly.");
        return response.json({ status: "403" });
    }

    let openId = steamIdString.split('openid/id/')[1];

    // store in session
    if (typeof request.session.openId === "undefined")
        request.session.openId = openId;

    // store user login info in db
    var userData = {
        openId: openId,
        lastlogin: new Date()
    };
    User.findOneAndUpdate({
        openId: openId
    }, userData, { upsert: true, useFindAndModify: false }, function (err, res) {
        
            // get redirection url
            let urlRedirection = getAppBaseUrl() + '/steam';
            console.log("user tried to sign in. redirecting to " + urlRedirection);

            // redirect
            response.redirect(urlRedirection);
            
    });

}

// Logout
export function steamLogout(req, res) {
    // delete from session
    if (isDefined(req.session.openId))
        delete req.session.openId;

    let urlRedirection = getAppBaseUrl() + '/steam';
    console.log("user tried to logout. redirecting to " + urlRedirection);
    res.redirect(urlRedirection);
}

// GamesInCommon
export function storeFriendsAsUsers(openid, friendsData, callback) {
    console.log("fetching friends of", openid);

    Async.eachSeries(friendsData, function updateObject(friend, done) 
    {
        let friendId = friend.steamid;
        if(!isDefined(friendId)) {
            console.log("not updating friend ", friend);
            done();
            return;
        }

        let id = 'friends.' + openid;

        User.findOneAndUpdate(
            { openId: friendId },
            { [id]: openid },
            { upsert: true, useFindAndModify: false },
            function (dberr, dbres) {
                done();
            }
        );
    }, function allDone(err) {
        callback();
    });
}

// GamesInCommon
export function retrieveAllUsersFromDB(req, res, next) {
    console.log("fetching all user data there is");

    User.find().exec((err, res) => {
        res.forEach((item, i) => {
            console.log("user", i, item.openId);
        });
    });
}

export function getSteamApiData(req, res, next) {
    console.log("[ " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString() 
        + " ] ===> serving Steam Web API data for", req.url);
    
    let realUrl = req.url.substring("/r3st/steam".length);

    // insert / replace apikey
    realUrl = realUrl.replace(/key=([\\d]+|null)/i, 'key=' + API_CONFIG.KEY);

    var options = {
        hostname: 'api.steampowered.com',
        path: realUrl
    };

    https.get(options, function (proxyRes) 
    {
        const data = [];
        proxyRes.on('data', d => data.push(d));
        proxyRes.on('end', () =>
        {
            let joint = data.join('');
            let steamAnswerObj = parseJson(joint);

            steamDataAction(res, next, realUrl, steamAnswerObj);
        });
    });
}

export function steamDataAction(res, next, apiPath, steamAnswerObj) 
{
    /*** check which action applies ***/
    
    if (STEAM_API.FRIENDS.regex.test(apiPath)) 
    {
        //--- friends action: store in db
        let openId = apiPath.match(STEAM_API.FRIENDS.regex)[1];
        if(!isDefined(openId)) {
            console.error("--> cannot store friends data for " + apiPath);
            next();
            return;
        }

        let friendslist = steamAnswerObj.friendslist;
        if (!isDefined(friendslist)) {
            console.log("[!] problem with " + apiPath);
            res.status(500);
            res.json(null);
            return;
        }

        let friendsNice = friendslist.friends;
        User.findOneAndUpdate({
            openId: openId
        },
        { friends: friendsNice },
        { upsert: true, useFindAndModify: false },
        function (dberr, dbres) {
            // Deal with the response data/error
            if (dberr) {
                console.error(dberr);
                res.status(500);
                next();
                return;
            }

            // update all users
            storeFriendsAsUsers(openId, friendsNice, function() {
                console.log("updated user " + openId + " and his friends in db");
                res.json(friendsNice);
                return;
            });
        });
    } else
    if (STEAM_API.PROFILE.regex.test(apiPath)) 
    {
        //--- profile action: store in db
        let openId = apiPath.match(STEAM_API.PROFILE.regex)[1];
        if (!isDefined(openId)) {
            console.log("--> not storing profile data for " + apiPath);
            next();
            return;
        }

        let response = steamAnswerObj.response;
        if (!isDefined(response)) {
            console.log("[!] problem with " + apiPath);
            res.status(500);
            res.json(null);
            return;
        }

        let player = response.players[0];
        User.findOneAndUpdate({
            openId: openId
        },
        { summary: player },
        { upsert: true, useFindAndModify: false },
        function (dberr, dbres) {
            // Deal with the response data/error
            if (dberr) {
                console.error(dberr);
                res.status(500);
                next();
                return;
            }

            res.json(player);
        });
    } else
    if (STEAM_API.GAMES.regex.test(apiPath)) 
    {
        //--- games action: store in db
        let openId = apiPath.match(STEAM_API.GAMES.regex)[1];
        console.log("caching games list for user " + openId);

        if (!isDefined(openId)) {
            console.log("--> not storing games data for " + apiPath);
            next();
            return;
        }

        let response = steamAnswerObj.response;
        if (!isDefined(response)) {
            console.log("[!] problem with " + apiPath);
            res.status(500);
            res.json(null);
            return;
        }

        let games = response;
        User.findOneAndUpdate({
            openId: openId
        },
        { games: games },
        { upsert: true, useFindAndModify: false },
        function (dberr, dbres) {
            // Deal with the response data/error
            if (dberr) {
                console.error(dberr);
                res.status(500);
                next();
                return;
            }

            res.json(games);
        });
    } else {
        console.error("[!] we have no action defined for " + apiPath);
        res.status(404);
        res.json({error: 'no action suitable'});
    }
}

export function compareSummary(a, b) {
    if (!a.summary) {
        return 1;
    }

    if (a.summary < b.summary) {
        return -1;
    }
    if (a.summary > b.summary) {
        return 1;
    }
    return 0;
}

export function getUserByOpenIdFromDB(request, response) {
    User.findOne({
        openId: request.params.openId
    }, function (err1, user) {
        if (err1)
            return response.send(err1);

        // merge friends object
        if (!inObjDefined([user, "friends"])) {
            console.log("user has no friends!");
            return response.send(user);
        }

        User.find({
            friends: {
                $elemMatch: {
                    steamid: request.params.openId,
                    // $sort: {
                    //     "summary.personaname": -1
                    // }
                },
            }
        })
        // .sort({
        //     "summary.personaname": -1
        // })
        // .lean()
        .exec((errInner, profiles) => {
            if (errInner)
                return response.send(errInner);

            let friendsCopy = user.friends.toObject();
            // friendsCopy.forEach((val, index) => {
            //     friendsCopy[index] = { ...friendsCopy[index], ...profiles[index].toObject()}
            // });

            mergeObjectsById(friendsCopy, profiles, "openId", "steamid");

            // prepare output object
            let output = { ...user.toObject() };
            output.countFriends = user.friends.length;
            output.friends = friendsCopy;

            // output["friends"] = friendProfiles;
            // Array.prototype.push.apply(friendData, res);
            // let arr = [...user.friends, friendData];
            // friendData = arr.sort(compareSummary);
            //user.friends = userFriends;
            // result.friends = friendData;
            // Array.prototype.push.apply(result, friendData);
            return response.send(output);
        });

        // User
        //     .aggregate([
        //         { $unwind: "$summary" },
        //         { $sort: { "summary.personaname": 1 } },
        //         { $match: {
        //             // { $unwind: "$friends" },
        //             friends: {
        //                 $elemMatch: {
        //                     openId: request.params.openId,
        //                     // $sort: {
        //                     //     "summary.personaname": -1
        //                     // }
        //                 },
        //             }
        //         }}
        //         // { $group: { openId: "$openId", summary: { $push: "$summary" } } }
        //         //{ $merge: { into: "myOutput", on: "_id", whenMatched: "replace", whenNotMatched: "insert" } }
        //     ])
        //     .exec((errInner, friendProfiles) => {
        //         if (errInner)
        //             return response.send(errInner)

        //         let friendData = user.friends;
        //         Array.prototype.push.apply(friendData, friendProfiles);
        //         let arr = [...user.friends, friendData];
        //         friendData = arr.sort(compareSummary);
        //         user.friends = friendData;
        //         // result.friends = friendData;
        //         // Array.prototype.push.apply(result, friendData);
        //         response.send(user);
        //         //response.json(user);

        //         return;
        //     });

        // User
        //     .aggregate([
        //         { $unwind: "$summary" },
        //         { $sort: { "summary.personaname": 1 } },
        //         // { $group: { _id: "$_id", summary: { $push: "$summary" } } }
        //     ])
    });
}


/** humbleZone schemata */
export const schemaGameKey = new Schema({
    name: String,
    key: String,
    type: String,
    instruction: String,
    claimStatus: String,
    claimer: String,
});

export const GameKey = mongoose.model('GameKey', schemaGameKey);

export function fetchAllGameKeys(req, res) {
    console.log("searching for gamekeys in db...");

    GameKey.find(function (err, games) {
        if (err)
            return res.send(err);

        let used = 0;
        games.forEach((val, index) => {
            if (val.claimStatus === "claimed")
                used++;
        });

        return res.json({
            count: games.length,
            countUsed: used,
            games: games
        });
    });
}

export function claimGameKey(req, res) {
    console.log("claiming gamekey in db...");
    let openId = req.params.openId;
    let keyId = req.params.key;

    if (!isDefined(openId) || !isDefined(keyId))
        return res.json({success: false});

    let newStatus = {
        claimStatus: 'claimed',
        claimer: openId
    };

    GameKey.findOneAndUpdate({
        key: keyId
    }, newStatus, { upsert: true, useFindAndModify: false }, (error, result) =>
    {
        return res.json(result);
    });
}



// redirect request to steam via server
// let optsAxios = {
//     url: realUrl,
//     baseURL: 'http://api.steampowered.com',
//     method: req.method,
//     httpsAgent: new https.Agent({
//         rejectUnauthorized: false
//     }),
//     //headers: req.headers
//     // headers: {
//     //     "Accept": "application/json, text/plain, */*",
//     //     //"User-Agent": "axios/0.18.0",
//     //     "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.169 Safari/537.36",
//     //     //"Content-Length": 2,
//     //     //"Host": "www.google.de",
//     //     //"Connection": "close",
//     // },
//     responseType: 'stream'
// };

// // optionally include request data
// if (isDefined(req.body)) {
//     optsAxios["data"] = JSON.stringify(req.body);
// }


// wait for steam response, then send own response back
// Axios(optsAxios)
// .then(proxyRes => {
//         // TODO error handling
//         //console.log(response.data);
//         // This is fast!!!
//         var pipe = proxyRes.data; // .pipe(res)
//         var data;
//         pipe.on('data', function (chunk) {
//             data += chunk;
//         });
//         pipe.on('end', function () {
//             console.log(data);
//             // res.end(data);
//             // proxyRes.data.pipe(data);
//             res.json(data);
//         });

//         // pipe chunked data fully
//         // response.data.pipe(a).on('finish', () => {
//         // });
//         //res.send(response.data);
//     }
// })
// .catch(error => {
//     if (isDefined(error.code)) {
//         console.log(error.code, "error <===");
//         res.status(503);
//         res.json({ "message": error.code, "tip": "no shit allowed" });
//     } else {
//         console.error(error, "<== proxy data: No error code available!");
//         res.status(500);
//         next();
//     }
// });
