import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
// import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Button, Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        textAlign: 'center',
        padding: theme.spacing(3)
    },
    stepper: {
        background: 'transparent',
        margin: 0,
        padding: 0
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        // marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(1),
    },
});

class ProgressStepper extends React.Component {

    render() {
        const { classes, activeStep, handleNext, handleReset, isStepFailed, isStepOptional, isStepSkipped, getSteps, getStepContent } = this.props;
        const steps = getSteps();

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs sm>
                        <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => {
                                const props = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    // props.completed = false;
                                    labelProps.optional = (
                                        <Typography variant="caption" color="textSecondary">
                                            Optional
                                        </Typography>
                                    );
                                }
                                if (isStepFailed(index)) {
                                    labelProps.error = true;
                                    // labelProps.label = (
                                    //     <Typography variant="caption" color="error">
                                    //         Failed
                                    //     </Typography>
                                    // );
                                }
                                if (isStepSkipped(index)) {
                                    props.completed = false;
                                    // labelProps.label = (
                                    //     <Typography variant="caption" color="error">
                                    //         Skipped
                                    //     </Typography>
                                    // );
                                }
                                return (
                                    <Step key={label} {...props}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Grid>
                    <Grid item xs sm={3}>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={handleReset}>Reset</Button>
                            </div>
                        ) : (
                            <div>
                                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                <div>
                                    {/* 
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button> */}
                                    <Button variant="contained" color="primary" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                    {/* {activeStep !== steps.length &&
                                        (completed.has(activeStep) ? (
                                        <Typography variant="caption" className={classes.completed}>
                                            Step {activeStep + 1} already completed
                                        </Typography>
                                    ) : (
                                        <Button variant="contained" color="primary" onClick={this.handleComplete}>
                                            {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                                        </Button>
                                    ))}

                                    {isStepOptional(activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSkip}
                                        className={classes.button}
                                    >
                                        Skip
                                    </Button>
                                    )} 
                                    */}
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

ProgressStepper.propTypes = {
    classes: PropTypes.object,
    activeStep: PropTypes.number.isRequired
};

export default withStyles(styles)(ProgressStepper);
