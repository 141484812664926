import axios from 'axios';
import store from 'store';

export function storeLocal(key, value) {
    window.localStorage.setItem(key, value);
    return value;
}

export function loadLocal(key) {
    return store.get(key);
}

export function isDefined(elem) {
    return ("undefined" === typeof elem || null === elem || "" === elem || {} === elem) === false;
}

export const mergeObjectsById = (a1, a2, byLeft, byRight) => {
    a1.map(itm => ({
        ...a2.find((item) => (item[byRight] === itm[byLeft]) && item),
        ...itm
    }));
    // let merged = [];
    // for (let i = 0; i < a1.length; i++) {
    //     merged.push({
    //         ...a1[i],
    //         ...(a2.find((itmInner) => itmInner[byRight] === a1[i][byLeft]))
    //     });
    // }
    // return merged;
}



export function inObjDefined(elemArr) {
    //console.log("checking", elemArr);
    let preObj;
    elemArr.forEach((item, index) => {
        console.log(index, item);
        if (preObj == null)
            preObj = item;
        if (!isDefined(preObj))
            return false;

        if (!isDefined(preObj["item"]))
            return false;
        preObj = preObj["item"];
    });
    if(!isDefined(preObj))
        return false;
    return true;
}

export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function takeEither(object1, object2) {
    if(object1 != null && object1 !== 'undefined') {
        return object1;
    }
    else if(object1 != null && object1 !== 'undefined') {
        return object2;
    }
    return null;
}

export function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function parseJson(data) {
    return Array.isArray(data) ? Array.from(data) : 
        (isJson(data) ? JSON.parse(data) : 
            data);
}

export function getNestedChildren(arr, parent) {
    let out = []
    let i;
    for(i in arr) {
        if(arr[i].parent === parent) {
            let children = getNestedChildren(arr, arr[i].id)
            if(children.length) {
                arr[i].children = children
            } else {
                arr[i].children = [];
            }
            out.push(arr[i])
        }
    }
    return out
}

export function sortByKey(array, key) {
    return array.sort(function(a, b) {
        let x = a[key]; let y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

export function sortByKeys(array, key, key2) {
    return array.sort(function(a, b) {
        let keyx = a[key],
            keyy = b[key],
            key2x = a[key2],
            key2y = b[key2];
        return keyx - keyy || key2x - key2y;
    });
}

export function findObjectByKeyValue(obj, key, value) {
    if(obj[key] === value) { return obj; }
    let i;
    for(i in obj) {
        if(obj.hasOwnProperty(i)){
            let found = findObjectByKeyValue(obj[i], key, value);
            if(found) {return found;}
        }
    }
    return null;
};

export function clone(obj) {
    if (null === obj || "object" != typeof obj) return obj;
    let copy = obj.constructor();
    let attr;
    for (attr in obj) {
        if (obj.hasOwnProperty(attr))
            copy[attr] = obj[attr];
    }
    return copy;
}



/* Basic Functions */
export function isInt(data) {
    return "number" === typeof parseInt(data, 10);
}

export function pad(int, count, char) {
    let str = "" + int;
    let gad = "";
    let i;
    for(i = 0; i < count; i+=1) {
        gad += char != null ? char : " ";
    }
    let ans = gad.substring(0, gad.length - str.length) + str;
    return ans;
}

export function getMilliSeconds(num) {
    return (num % 1) * 1000;
}

export function getYoutubeVideoIdFromUrl(currentMedium) {
    let regex = new RegExp('https?://.*?v=([a-zA-Z0-9]+)', 'i');

    if(!isDefined(currentMedium))
        return null;

    let found = currentMedium.match(regex);
    if(!isDefined(found)) {
        return null;
    }

    //var urlFull = found[0];
    var vidId = found[1];
    return vidId;
}

export function isYoutubeVideo(url) {
    if(url === null || typeof url !== 'string')
        return false;
    return url.match(/http(s)?:\/\/(www\.)?youtu(\.)?be(.com)?/gi);
}



export function getInitials (text, glue) {
    if(!isDefined(text))
        return "";

    if (typeof glue === "undefined") {
        glue = true;
    }
    var initials = text.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
    if (glue) {
        return initials.join('');
    }
    return initials;
};

export function capitalize(text) {
    return text.toLowerCase().replace(/\b\w/g, function (m) {
        return m.toUpperCase();
    });
};



/* URL Functions */
export function getViaAjax(options = {
    method: 'GET',
}, functions = {
    "success": {}, "error": {}, "always": {}
}) {
    axios(options)
    //.then(response => response.json())
    .then(response => {
        try {
            if(!isDefined(response.data)) {
                console.error("Got back no data!");
                if (isDefined(functions["errorFunc"]))
                    functions.errorFunc(response);
                return;
            }
        
            let parsed = parseJson(response.data);
            if (isFunction(functions["success"]))
                functions.success(parsed);
        } catch(e) {
            console.error("getViaAjax error:",e);
        }
    })
    .catch(problem => {
        if (isFunction(functions["error"]))
            functions.error(problem);
        else
            console.error(problem, "<= uncaught ajax error");
    }).finally((what) => {
        if (isFunction(functions["always"]))
            functions.always(what);
    });
}

export function getProxyUrl(wholeUrl) {
    return (process.env.NODE_ENV === "production" ? 'https:' : window.location.protocol) 
        + '//' + window.location.hostname 
        + ':' + (process.env.NODE_ENV === "production" ? (parseInt(process.env.REACT_APP_PORT_SERVER)) : (parseInt(process.env.REACT_APP_PORT_SERVER) + 10))
        + '/'; // backslash required
}

export function getHeaders(bearer) {
    let newHeaders = {
        'Content-Type': 'application/json'
    };
    if(isDefined(bearer)) {
        newHeaders['Authorization'] = 'bearer ' + bearer;
    }
    return newHeaders;
}

export function getFromUrlOrStorage(paramId, storageId, fallback) {
    let urlParam = getUrlParam(paramId);
    let stoParam = loadLocal(storageId);
    if (isDefined(urlParam)) {
        return urlParam;
    }
    if (isDefined(stoParam)) {
        return parseJson(stoParam);
    }
    if (isDefined(fallback))
        return fallback;
    return null;
}

export function getUrlParam(name) {
    let results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results==null){
       return null;
    }
    else{
       return results[1] || 0;
    }
}


export function getFromStorageOrDefault(key, defVal) {
    let loaded = loadLocal(key);
    if(isDefined(loaded)) {
        return loaded;
    }
    return defVal;
}


export function removeURLParameter(theurl, parameter) {
    //console.log('removing url parameter', parameter, 'from', theurl);

    //prefer to use l.search if you have a location/link object
    let urlParts = theurl.split('?');
    if (urlParts.length >= 2) {
        let paramPrefix = encodeURIComponent(parameter) + '=';
        let urlParams = urlParts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        let i = urlParams.length - 1;
        while (i >= 0) {
            //idiom for string.startsWith
            if (urlParams[i].lastIndexOf(paramPrefix) !== -1) {
                urlParams.splice(i, 1);
            }
            i -= 1;
        }

        let returl = urlParts[0] + (urlParams.length > 0 ? '?' + urlParams.join('&') : "");
        //console.log("returning", returl);
        return returl;
    } else {
        return theurl;
    }
}

