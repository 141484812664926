import React from 'react';
import { Typography } from '@material-ui/core';
import { isDefined } from '../../helpers';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    infoSmall: {
        padding: theme.spacing(2),
        textAlign: 'center'
    }
});

function TrackInfoSmallClass(props) {
    let { medium, classes } = props;
    let { playing, artist, title } = medium;

    let col = 'textSecondary'
    if(playing)
        col = 'textPrimary'
    
    return (
        <div className={classes.infoSmall} disabled={!playing}>
            <Typography variant="h5" color={col}>{artist}</Typography>
            <Typography variant="h6" color={col}>{title}</Typography>
        </div>
    )
}

TrackInfoSmallClass.propTypes = {
    classes: PropTypes.object,
    medium: PropTypes.object
};

const TrackInfoSmall = withStyles(styles)(TrackInfoSmallClass);
export {
    TrackInfoSmall
}


export class TrackInfoSlider extends React.Component {
    constructor(props) {
        super(props);
        this.clickSurface = React.createRef();
    }

    render() {
        let { duration, current, aim, botSeek } = this.props;

        if(!isDefined(current))
            return;

        let percentCur = Math.round(current / duration);
        let percentAim = Math.round((aim / duration) * 100);

        // hold info without setting state
        let X = 0;
        //let Y = 0; 

        return (
            <div ref={this.clickSurface} style={{ background: 'white', height: '10px', width: '100%', position: 'relative' }} 
                onMouseMove={(e) => {
                    const { clientX } = e;
                    X = clientX;
                }} 
                onClick={(e) => {
                    let contWidth = this.clickSurface.current.offsetWidth;
                    let contOffsetX = this.clickSurface.current.left;
                    let relOffsetX = X - contOffsetX;
                    let percentClick = Math.floor(relOffsetX / contWidth * 100);
                    console.log("relOffsetX", relOffsetX, "contWidth", contWidth, "=", "percentClick", percentClick);
                    botSeek(percentClick);
                }}
            >
                <div style={{ width: percentCur + '%', background: 'red', height: '10px', position: 'absolute', left: 0 }}></div>
                <div style={{ width: percentAim + '%', background: 'blue', height: '10px', position: 'absolute', left: 0 }}></div>
            </div>
        )

    }
}

TrackInfoSlider.propTypes = {
    duration: PropTypes.number,
    current: PropTypes.number,
    aim: PropTypes.number,
    botSeek: PropTypes.func.isRequired,
};
