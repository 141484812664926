// react basics
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SyncIcon from '@material-ui/icons/SyncOutlined';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabledOutlined';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {
    isDefined,
} from '../../helpers';
import Syncer from './syncer';
import { MainOverlay } from './overlays';
import { TrackInfoSmall } from './trackinfo';
import MediaPlayer from './mediaplayer';
import ProgressStepper from './progress-stepper';
import { SlideoutSections } from './slideout';
import LoginIcon from '@material-ui/icons/Call';
import LogoutIcon from '@material-ui/icons/CallEnd';
// import SyncProblemIcon from '@material-ui/icons/SyncProblemOutlined';
// import SwapIcon from '@material-ui/icons/PermMedia';
import ListIcon from '@material-ui/icons/ListRounded';
import BgImg from './img/bg-tile-01.png';
// import BgImg from './img/stripes.gif';
// import './sass/app.sass';
import { fetchInstanceInfos, authenticate, fetchDefaultBotId, botPlay, botStop, botSeek, botNext, botPrev, getStreamToken, getAudioStream, botAddJob, botAddStreaming, botGetLastStreamed } from './functions-api';
import { storeAndState, getFromUrlStoreAndState, removeSetting, handleUserStateChange, logout, handleFormLogin, increaseSyncCount, setInstances, setMessage, setMedium, toggleSync, syncManual, toggleMessageVisibility, chooseInstance, setChannels, setJobs } from './functions-storage';
import { getSteps, getStepContent, handleStepperIncrease, handleStepperFail, handleStepperNext, handleStepperStep, handleStepperBack, handleStepperReset, handleStepperSkip, isStepperStepOptional, isStepperStepFailed, isStepperStepSkipped, handleComplete, completedSteps, totalSteps, showStepInstanceSelection } from './functions-stepper';
import { withSnackbar } from 'notistack';
import { ErrorBoundary } from '../boundaries';
import { useFallbackMedium, onPlayerError, onDuration, onPlayerCheck, getPlayer } from './functions-media';

const styles = theme => ({
    '@global': {
        body: {
            // background: '#222 url(' + BgImg + ') no-repeat fixed center center / cover',
            background: '#222 url(' + BgImg + ') repeat center top',
            transition: 'background-color 1s ease'
        }
    },
    root: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        marginBottom: theme.spacing(2),
    },
    controls: {
        background: theme.palette.primary.main,
        textAlign: 'center'
    },
    sidebarPaper: {
        background: "#0b0b0b",
        width: 320
    },
    sixteen: {
        position: 'relative',
        '&:before': {
            display: 'block',
            width: '100%',
            content: '""',
            paddingTop: ((9 / 16) * 100) + '%'
        },
    }

});


const swTheme = createMuiTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#bcbcbc'
        },
        primary: {
            light: '#EFEFEF',
            main: '#888',
            dark: '#550505',
        },
        secondary: {
            light: '#770077',
            main: '#560056',
            dark: '#330033',
        },
        text: {
            primary: '#EEE',
            secondary: '#DDD',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: 
            //'-apple-system,system-ui,BlinkMacSystemFont,' +
            '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
    },
});


// const MP = React.forwardRef((props, ref) => (
//     <MediaPlayer
//         {...props}
//         ref={ref}
//     />
// ));

class SyncWatch extends Component 
{
    constructor(props) {
        super(props);
        
        this.state = {
            slideoutOpen: false,
            authSteps: {
                current: 0,
                failed: -1,
                skipped: new Set(),
                completed: new Set()
            },
            user: {
                token: null,
                botId: null,
                streamUrl: '',
                streamToken: '',
                username: '',
                password: '',
                instance: '',
                instances: []
            },
            synchronization: {
                interval: 2000,
                active: false,
                count: 0
            },
            medium: {
                title: '',
                artist: '',
                playing: false,
                position: 0,
                duration: 0,
                type: ''
            },
            channels: {},
            jobs: {},
            message: {
                visible: false
            },
            net: {}
        };

        this.mediaplayer = React.createRef();
    }
    
    // triggered after the first render
    componentDidMount() 
    {
        this.handleStepperNext();
    }

    

    /***** PROGRESS STEPPER *****/
    getSteps = getSteps.bind(this);
    getStepContent = getStepContent.bind(this);
    handleStepperIncrease = handleStepperIncrease.bind(this);
    handleStepperFail = handleStepperFail.bind(this);
    handleStepperNext = handleStepperNext.bind(this);
    handleStepperStep = handleStepperStep.bind(this);
    handleStepperBack = handleStepperBack.bind(this);
    handleStepperReset = handleStepperReset.bind(this);
    handleStepperSkip = handleStepperSkip.bind(this);
    isStepperStepOptional = isStepperStepOptional.bind(this);
    isStepperStepFailed = isStepperStepFailed.bind(this);
    isStepperStepSkipped = isStepperStepSkipped.bind(this);
    handleComplete = handleComplete.bind(this);
    completedSteps = completedSteps.bind(this);
    totalSteps = totalSteps.bind(this);

    /***** SLIDEOUT & MESSAGE*****/
    handleSlideoutToggle = () => this.setState({ slideoutOpen: !this.state.slideoutOpen });
    handleSlideoutClose = () => this.setState({ slideoutOpen: false });
    toggleMessageVisibility = toggleMessageVisibility.bind(this);

    /***** SINUSBOT API *****/
    fetchInstanceInfos = fetchInstanceInfos.bind(this);
    authenticate = authenticate.bind(this);
    fetchDefaultBotId = fetchDefaultBotId.bind(this);
    getStreamToken = getStreamToken.bind(this);
    getAudioStream = getAudioStream.bind(this);
    botPlay = botPlay.bind(this);
    botStop = botStop.bind(this);
    botSeek = botSeek.bind(this);
    botNext = botNext.bind(this);
    botPrev = botPrev.bind(this);

    /***** PLAYER  *****/
    onPlayerError = onPlayerError.bind(this);
    onPlayerCheck = onPlayerCheck.bind(this);
    onDuration = onDuration.bind(this);
    getPlayer = getPlayer.bind(this);


    /***** STORAGE *****/
    storeAndState = storeAndState.bind(this);
    getFromUrlStoreAndState = getFromUrlStoreAndState.bind(this);
    removeSetting = removeSetting.bind(this);
    handleUserStateChange = handleUserStateChange.bind(this);
    handleFormLogin = handleFormLogin.bind(this);
    logout = logout.bind(this);

    hasToken = () => isDefined(this.state.user.token);
    hasInstance = () => isDefined(this.state.user.instance);
    hasInstances = () => isDefined(this.state.user.instances);
    getInstances = () => this.state.user.instances;

    increaseSyncCount = increaseSyncCount.bind(this);
    setInstances = setInstances.bind(this);
    setMessage = setMessage.bind(this);
    setMedium = setMedium.bind(this);
    setChannels = setChannels.bind(this);
    setJobs = setJobs.bind(this);
    botAddJob = botAddJob.bind(this);
    botAddStreaming = botAddStreaming.bind(this);
    botGetLastStreamed = botGetLastStreamed.bind(this);
    useFallbackMedium = useFallbackMedium.bind(this);
    
    /***** Synchronization *****/
    toggleSync = toggleSync.bind(this);
    syncManual = syncManual.bind(this);

    /***** Login, Logout *****/
    showStepInstanceSelection = showStepInstanceSelection.bind(this);
    chooseInstance = chooseInstance.bind(this);


    /*** Main DOM Rendering ***/
    render() {
        const { classes } = this.props;
        const { synchronization, user, slideoutOpen, medium, authSteps } = this.state;

        // include syncer if signed in
        let syncer = [];
        if (this.hasToken() && this.hasInstance() && synchronization.active) {
            syncer = (
                <Syncer 
                    active={synchronization.active}
                    instance={user.instance}
                    interval={synchronization.interval}
                    headers={this.state.net.headers} 
                    hasInstance={this.hasInstance}
                    medium={this.state.medium}
                    user={user}
                    setMedium={this.setMedium} 
                    increase={this.increaseSyncCount} 
                    player={this.mediaplayer}
                    streamUrl={user.streamUrl}
                    setChannels={this.setChannels}
                    setJobs={this.setJobs}
                    botGetLastStreamed={this.botGetLastStreamed}
                    useFallbackMedium={this.useFallbackMedium}
                    slideoutOpen={slideoutOpen}
                />
            );
        }

        // show status stepper or trackinfo
        let stepsOrTrackinfo;
        if(this.state.authSteps.current < 4) 
        {
            stepsOrTrackinfo = (
                <ProgressStepper 
                    activeStep={authSteps.current} 
                    completed={authSteps.completed}
                    getSteps={this.getSteps}
                    getStepContent={this.getStepContent}
                    handleNext={this.handleStepperNext}
                    handleBack={this.handleStepperBack}
                    handleReset={this.handleStepperReset}
                    isStepFailed={this.isStepperStepFailed}
                    isStepOptional={this.isStepperStepOptional}
                    isStepSkipped={this.isStepperStepSkipped}
                    completedSteps={this.completedSteps}
                    totalSteps={this.totalSteps}
                />
            );
        }
        else 
        {
            stepsOrTrackinfo = (
                <TrackInfoSmall
                    medium={medium}
                />
            );
        }

        // conditional buttons
        let buttonLoginOut;    
        if (this.hasToken()) {
            buttonLoginOut = (
                <Button title={"Logout"} onClick={this.logout}>
                    <LogoutIcon />
                </Button>
            );            
        } else {
            buttonLoginOut = (
                <Button title={"Login/Reset"} onClick={this.logout}>
                    <LoginIcon />
                </Button>
            );
        }


        return (
            <MuiThemeProvider theme={swTheme}>
                <div className={classes.root}>
                <ErrorBoundary>

                    {syncer}
                    
                    {/* Slideout Contents */}
                    <Drawer 
                        classes={{ paper: classes.sidebarPaper }}
                        anchor="left"
                        docked="false"
                        // variant="permanent"
                        ModalProps={{ onBackdropClick: () => { this.setState({ slideoutOpen: !this.state.slideoutOpen }) }, disableEscapeKeyDown: false, onEscapeKeyDown: () => { this.setState({ slideoutOpen: !this.state.slideoutOpen }) }}}
                        open={this.state.slideoutOpen}
                        color={'#000000'}
                        // onRequestChange={(slideoutOpen) => this.setState({ slideoutOpen })}
                        // onClose={this.toggleDrawer('bottom', false)}
                        // onOpen={this.toggleDrawer('bottom', true)}
                    >
                        <SlideoutSections
                            botPlay={this.botPlay}
                            botStop={this.botStop}
                            botSeek={this.botSeek}
                            botNext={this.botNext}
                            botPrev={this.botPrev}
                            hasToken={this.hasToken}
                            hasInstance={this.hasInstance}
                            hasInstances={this.hasInstances}
                            showInstances={this.showStepInstanceSelection}
                            logout={this.logout}
                            medium={this.state.medium}
                            players={this.state.players}
                            net={this.state.net}
                            synchronization={synchronization}
                            channels={this.state.channels}
                            jobs={this.state.jobs}
                            botAddJob={this.botAddJob}
                            botAddStreaming={this.botAddStreaming}
                            botGetLastStreamed={this.botGetLastStreamed}
                        />
                    </Drawer>

                    {/* section player */}
                    <div className={classes.sixteen + " callout noselect text-center section-player sixteen-nine"}>

                        <MainOverlay 
                            message={this.state.message}
                        />

                        <MediaPlayer 
                            user={user}
                            medium={this.state.medium}
                            controls={synchronization.active}
                            hasToken={this.state.hasToken}
                            hasInstance={this.state.hasInstance}
                            setMedium={this.setMedium}
                            logout={this.state.logout}
                            ref={this.mediaplayer}
                            onPlayerCheck={this.onPlayerCheck}
                            onPlayerError={this.onPlayerError}
                            onDuration={this.onDuration}
                        />
                    </div>
                    <div className={classes.controls}>
                        <Button
                            disabled={this.state.authSteps.current <= 2}
                            className="toggle-slideout" 
                            title="Toggle menu"
                            label="Toggle Drawer"
                            onClick={this.handleSlideoutToggle}
                        >
                            <MenuIcon />
                        </Button>

                        {buttonLoginOut}

                        <Button disabled={this.state.authSteps.current <= 3} title={"Show Instances"} onClick={this.showStepInstanceSelection}>
                            <ListIcon />
                        </Button>
                       
                        {/* Sync Toggle */}
                        <Button disabled={this.state.authSteps.current <= 3} title="Toggle Sync" onClick={this.toggleSync}>
                            <div className="spin">
                                {(synchronization.active ? <SyncIcon /> : <SyncDisabledIcon />)}
                            </div>
                        </Button>

                        {/* <audio autoPlay controls width={320} height={200} src={'http://127.0.0.1:8087' + this.state.medium.file}></audio> */}

                    </div>

                    {stepsOrTrackinfo}
                    
                </ErrorBoundary>
                </div>
            </MuiThemeProvider>
        )
    }
}

SyncWatch.propTypes = {
    classes: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(withSnackbar(SyncWatch));
