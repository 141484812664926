import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import RecipeCard from './recipeCard';
// import RecipeDialog from './recipeDialog';
import RecipeModal from './recipeModal';
import CardMedia from '@material-ui/core/CardMedia';
import { isDefined } from '../../helpers';
import { Chip } from '@material-ui/core';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  padded: {
    padding: theme.spacing(2),
  },
  results: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  media: {
    height: 'auto'
  },
  resultsItem: {
    padding: theme.spacing(1),
    
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },
    [theme.breakpoints.up('md')]: {
      width: '33%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%'
    },
    [theme.breakpoints.up('xl')]: {
      width: '12.5%'
    },
  }
});


class ResultList extends React.Component {

  state = {
    open: false,
    selected: {}
  }

  updateSelected = (item) => {
    this.setState({
      selected: item,
      open: true
    });
  }

  handleOpen = () => {
    let dia = { ...this.state.dialog };
    dia.open = true;
    this.setState({
      dialog: dia
    });
  };

  handleClose = () => {
    let dia = {...this.state.dialog};
    dia.open = false;
    this.setState({
      dialog: dia,
      open: false
    });
  };

  render() {
    const { classes, items } = this.props;
    const { selected } = this.state;

    let media;
    if (isDefined(selected.image)) {
      media = (<CardMedia
        component="img"
        alt={selected.name}
        className={classes.media}
        height="140"
        image={selected.image}
        title={selected.name}
      />);
    }


    let ingredients = [];
    if (typeof selected.ingredients !== "undefined" && selected.ingredients.length > 0) {
      selected.ingredients.map((item, i) => {
        ingredients.push(item);
        return ingredients;
      });
    }


    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" component="h3">
          {items.length} Rezepte zur Auswahl
        </Typography>
        <Typography component="p" gutterBottom>
          Es wurde(n) {items.length} aktuell Rezept(e) mit den/der ausgewählten Zutat(en) gefunden!
        </Typography>
        
        <div className={classes.results}>
          {items !== null && items.length > 0 && items.map((item, i) => {
            // Return the element. Also pass key     
            return (
              <div key={i} className={classes.resultsItem}>
                <RecipeCard key={i} {... item} updateSelected={this.updateSelected} />
              </div>
            )
          })}
        </div>

        {/* <RecipeDialog title={this.state.dialog.title} content={this.state.dialog.content} /> */}
        <RecipeModal handleClose={this.handleClose} open={this.state.open}>
          {media}
          <div className={classes.padded}>
            <Typography variant="h4">{selected.name}</Typography>
            <Typography component="cite">{selected.description}</Typography>
            {isDefined(selected.ingredients) && (
              <div>
                <Typography variant="h5">Zutaten:</Typography>

                {selected.ingredients.length > 0 && selected.ingredients.map((item, i) => {
                  let label = item.name;
                  if(item.quantity) {
                    label = item.quantity + " x " + label;
                  }

                  return (
                    <Chip
                      label={label}
                      key={i}
                      // color={color}
                      // deleteIcon={onDelete === 'custom' ? <DoneIcon /> : undefined}
                      // onDelete={onDelete !== 'none' ? this.handleDeleteExample : undefined}
                      // avatar={avatarToPlayground}
                      // icon={iconToPlayground}
                      variant="default"
                    />
                  )
                })}

                {/* <Typography component="pre">{JSON.stringify(selected.ingredients, null, 4)}</Typography> */}
              </div>
            )}

            {isDefined(selected.instruction) && (
              <div>
                <Typography variant="subtitle1">Anleitung:</Typography>
                <Typography component="cite">{JSON.stringify(selected.instruction)}</Typography>
              </div>
            )}

            {/* <Typography component="pre">{JSON.stringify(selected, {space: 4})}</Typography> */}
          </div>
        </RecipeModal>
      </Paper>
    );
  }
}

ResultList.propTypes = {
  classes: PropTypes.object.isRequired
  // theme: PropTypes.object.isRequired,
};

export default withStyles(styles,{withTheme: true })(ResultList);
