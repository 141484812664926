import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import HumbleList from './humbleList';
import { withSnackbar } from 'notistack';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
    }
});

class HumbleZone extends React.Component 
{
    state = {
        games: []
    };

    componentDidMount() {
        this.fetchAllGamekeys();
    }

    fetchAllGamekeys = () => {
        fetch('/r3st/games')
        .then(response => response.json())
        .then(data => this.setState({ games: data }))
        .catch((reason) => {
            this.props.enqueueSnackbar("Could not retrieve the list of games!", {
                variant: 'error'
            });
        });
    }


    render() {
        const { classes, ...other } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>HumbleZone <small>0.7</small></Typography>
                <Typography variant="caption">...weil auch der Weihnachtsmann manchmal zu früh kommt! ;)</Typography>
                
                <br/>
                <br/>
                <Divider />
                <br/>

                <Typography variant="h5" gutterBottom>
                    Was und warum
                </Typography>
                <Typography variant="body1" paragraph>
                    Hier kann man Game-Keys eintragen, die man selbst nicht benötigt. Das Formular dazu gibt es allerdings noch nicht!<br/>
                    Wer auf dieser Seite angemeldet und dazu berechtigt ist, kann sich einfach frei bedienen und Schlüssel nehmen, soviele er mag.
                </Typography>
                <Typography variant="body2" paragraph>
                    Es wäre einfach schade, wenn die Keys nur verfallen und nicht genutzt werden!
                </Typography>
                <Typography variant="subtitle1" paragraph>
                    Also: <i>nehmt und gebt Keys, Leude!</i>
                </Typography>

                <br />
                <Divider />
                <br />

                <HumbleList {...other} {...this.state} />
            </div>
        )
    }
}

HumbleZone.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(HumbleZone));
