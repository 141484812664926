import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Divider, Grid, Button } from '@material-ui/core';
import JSONPretty from 'react-json-pretty';
import { getProxyUrl } from '../../helpers';
import ImgSits from './img/sits_01.png';

const styles = theme => ({
    login: {
        padding: theme.spacing(2),
    },
    section: {
        margin: theme.spacing(2) + 'px 0',
        padding: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px',
    },
    sectionBelow: {
        margin: theme.spacing(2)
    },
    outline: {
        background: 'rgba(255,255,255,.1)',
        border: '1px solid rgba(255,255,255,.3)',
        borderRadius: 9,
        padding: theme.spacing(1)
    }
});

export const LoginOutButton = (props) => {
    const { signedIn, urlLogin } = props;
    if(signedIn === false) {
        return (
            <div>
                <Typography variant="caption">
                    Klicke auf den Button, um dich einzuloggen.
                </Typography>
                <br /><br/>
                <Link href={urlLogin}>
                    <img alt="Steam Login" title="Steam Login" src={ImgSits} />
                </Link>
            </div>
        );
    } else 
    if(signedIn === true) {
        return (
            <div>
                <Typography variant="caption">
                    Klicke nochmal auf den Button, um dich wieder auszuloggen.
                </Typography>
                <br /><br/>
                <Link href={getProxyUrl() + "r3st/steamlogout/"}>
                    <img alt="Steam Logout" title="Steam Logout" src={ImgSits} />
                </Link>
            </div>
        )
    }
    return null;
}

export function getLoginUrl(returnUrl) {
    // return  'http://steamcommunity.com/openid/id/' + steamid + "/";
    return 'https://steamcommunity.com/openid/login' +
        '?openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0' +
        '&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select' +
        '&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select' +
        '&openid.return_to=' +
        encodeURI(returnUrl) +
        //'https%3A%2F%2Fcsgolounge.com%2Fv1%2Fsteam%2Fcallback%2Fsignup%2FeyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzZXNzaW9uX2lkIjoiYjdlOGM0ZjUtMzFlMy0xMWU5LWFiNzQtMDY2Yzc5NzhlNTgzIiwiaWF0IjoxNTUwMzE4OTg5LCJleHAiOjE1NTA0MDUzODl9.655zOmy6LR3pnxG37Mi2HG3xjRMOwxLFf0TMtCCIwnU%2F'+
        '&openid.mode=checkid_setup';
}

class Login extends React.Component 
{
    render() {
        const { classes, signedIn, session } = this.props;
        //const { value } = this.state;

        return (
            <div className={classes.login}>
                <Typography variant="h4" gutterBottom>Login auf Steam <Link color="secondary" variant="subtitle2" href="https://openid.net" target="_blank">(via openID)</Link></Typography>

                <Divider />
                <br/>

                <Grid container spacing={10}>
                    <Grid align="center" item xs={12} md={4}>
                        <Typography variant="h5" gutterBottom>
                            Was und warum
                        </Typography>
                        <Typography paragraph>
                            Steam bietet eine öffentliche Schnittstelle an, mit der Entwickler in der Lage sind, eigene Dienste auf Basis von Steam-Daten zu schreiben.
                            Die Bekanntesten Beispiele sind vermutlich Tausch-Websites, die ihre eigenen Trading-Bots haben, die automatisiert Gegestände verschicken und annehmen.
                        </Typography>
                        <Typography paragraph>
                            Auf dieser Website geht es aktuell darum, sich weitere interessante Statistiken und Informationen über sein eigenes Profil und die Profile seiner Freunde zu verschaffen.
                        </Typography>
                        <Typography variant="h5">
                            Beispielsszenarien: 
                        </Typography>
                        <ul style={{ listStyle: "none" }}>
                            <li><i>- "wieviel ist mein Inventar aktuell auf dem Marktplatz wert?"</i></li>
                            <li><i>- "welche(s) Spiel(e) spielen meine Freunde und ich am liebsten?"</i></li>
                            <li><i>- "hat jemand einen Schlüssel für ein bestimmtes Spiel?"</i></li>
                        </ul>
                        <Typography paragraph>
                            Diese Fragen sollen durch diesen Dienst beantwortet werden.
                        </Typography>
                    </Grid>

                    <Grid align="center" item xs={12} md={4}>
                        <Typography variant="h5" gutterBottom>
                            Voraussetzungen
                        </Typography>
                        <Typography paragraph>
                            Damit du die Steam-Sektion hier für deine Zwecke nutzen kannst, musst du einen Steam-Account besitzen und dich über den Login-Button auf diesem Webdienst anmelden.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Erklärung
                        </Typography>
                        <Typography paragraph>
                            Der Login-Prozess funktioniert durch Weiterleitungen, bei denen deine Browser-Session mit bestimmten Werten befüllt wird. Diese Werte geben Auskunft darüber, ob der Login geklappt hat und welche Steam-ID du hast.
                        </Typography>
                        <Typography paragraph>
                            Mithilfe der Steam-ID und der API-Methoden, die Steam zur Verfügung stellt, kann man z.B. das <i>Profil des Nutzers</i>, die <i>Freundesliste</i> und <i>Statistiken</i> zu Spielen bekommen, die der User spielt oder gespielt hat.<br/>
                            Das widerum erlaubt die Erstellung eigener Statistiken oder Dienste auf Basis der Daten.
                        </Typography>
                        
                        <Divider />
                        <br />

                        <Typography variant="caption">
                            Wenn mehr über die Steam-API und was sie hergibt erfahren möchtest, klicke
                            &nbsp;<Button variant="outlined" size="small" component="a" href="https://steamcommunity.com/dev" target="_blank" className={classes.button}>hier</Button>&nbsp;.
                        </Typography>
                    </Grid>

                    <Grid align="center" item xs={12} md={4}>
                        <div className={classes.outline}>
                            {!signedIn && (
                                <div>
                                    <Typography variant="h5" gutterBottom>
                                        Ablauf
                                    </Typography>
                                    <Typography paragraph>
                                        <b>Der Prozess sieht so aus:</b> Du wirst nach einem Klick auf den Button zum Anmelden auf Steam weitergeleitet.<br/>
                                    </Typography>
                                    <Typography paragraph>
                                        Wenn du dich dort eingeloggt hast, wirst du wieder zurück auf diese Seite geleitet und bist automatisch auch hier eingeloggt.
                                    </Typography>
                                    <Typography paragraph>
                                        Beim Einloggen werden Daten von Steam zu deinem Profil abgerufen und in der Datenbank abgelegt.
                                        Du kannst diese Daten jederzeit einsehen, aktualisieren oder auch löschen lassen.<br/>
                                        Ohne deine Daten kann dieser Dienst nicht funktionieren.
                                    </Typography>
                                </div>
                            )}
                            {signedIn && (
                                <div>
                                    <Typography variant="h5" gutterBottom>Login Info</Typography>
                                    <Typography variant="subtitle1" component="p" gutterBottom>
                                        Hier sieht du Infos über deine aktuelle Session.
                                    </Typography>

                                    <Divider variant="middle" />

                                    <div className={classes.section}>
                                        <JSONPretty json={session} style={{ color: '#EEE' }} />
                                    </div>
                                </div>
                            )}

                            <Divider />

                            <LoginOutButton signedIn={signedIn} urlLogin={getLoginUrl(this.props.urls.return)} />
                        </div>

                    </Grid>
                    <Grid item xs={12} align="center">

                    </Grid>
                </Grid>
            </div>
        )
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

//export default App;
export default withStyles(styles)(Login);
