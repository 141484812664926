import store from 'store';
import { isDefined, getFromUrlOrStorage, parseJson, getViaAjax, sortByKeys, getNestedChildren } from '../../helpers';
import async from 'async';

export function logout() {
    console.log("Trying to logout!")
    this.removeSetting("instance");
    this.removeSetting("instances");
    this.removeSetting("instanceName");
    this.removeSetting("botId");
    this.removeSetting("token");
    const me = this;
    this.setState({
        user: {}
    }, () => {
        console.log("Reset started!")
        me.handleStepperReset(() => {
            console.log("Reset completed!")
            me.handleStepperNext();
        });
    });
}

export function storeAndState(key, value, callback) 
{
    // save to browser cache and user state
    // console.debug(">>> caching [", key, "]:[", value, "]");

    // parse if needed
    let safeValue = value;
    if (typeof value === "object")
        safeValue = JSON.stringify(value);
    store.set(key, safeValue);

    this.setState({
        user: {
            ...this.state.user,
            [key]: value
        }
    }, function () {
        if (isDefined(callback))
            callback()
    });

    return value;
}

export function getFromUrlStoreAndState(urlParam, storeParam, callback) 
{
    /* load from browser cache or url into user state */
    if (!isDefined(storeParam))
        storeParam = urlParam;

    let value = getFromUrlOrStorage(urlParam, storeParam);

    if (isDefined(value)) {
        // console.debug("<<< cache [", storeParam, "]:[", value, "]");
        this.storeAndState(storeParam, parseJson(value), () => {
            if (isDefined(callback))
                callback(value)
        });
    } else {
        if (isDefined(this.state.user[storeParam])) {
            value = this.state.user[storeParam];
            // console.debug("<<< state [", storeParam, "]:[", value, "] type:", typeof value);
            if (isDefined(callback))
                callback(value)
        }
        // console.warn("< cannot find", storeParam, "in", this.state.user, "returning", value);
        if (isDefined(callback))
            callback(value)
    }
}

export function removeSetting(key) 
{
    /* remove from browser cache and user state */
    console.warn("x removed", key);
    // window.localStorage.removeItem(key);
    store.remove(key);
    this.setState({
        user: {
            ...this.state.user,
            [key]: null
        }
    });
}

export function handleUserStateChange(event) 
{
    let { name, value } = event.target;
    // console.debug("handling user state change for "+name+": "+value);


    // cache in state
    this.setState({
        user: {
            ...this.state.user,
            [name]: value
        }
    }, () => {
        // cache in browser
        store.set(name, value);

        // explicitly handle change of the instance-selection
        if (name === "instance") {
            console.log("Instance selected:", value);
            this.handleStepperIncrease(this.handleStepperNext, true);
        }
    });
};

export function handleFormLogin(event) {
    console.debug("handleFormLogin");
    event.preventDefault();
    let {
        user,
    } = this.state;
    let {
        botId,
        username,
        password
    } = user;

    console.debug("Trying to submit form with", event, user);

    // prepare login-data
    let creds = {
        "username": username,
        "password": password,
        "botId": botId
    };

    if (!isDefined(creds.username) || !isDefined(creds.password)) {
        this.props.enqueueSnackbar("Please enter valid credentials.", {
            variant: 'error'
        });
        return;
    }

    if (!isDefined(creds.botId)) {
        this.props.enqueueSnackbar("The default login botId was not found! Something's wrong!");
        return;
    }

    let me = this;

    // log in / retrieve token
    getViaAjax({
        url: '/api/v1/bot/login',
        method: 'POST',
        data: creds
    }, {
        success: function (data) {
            let dataLogin = parseJson(data);
            console.log("Tried login with", creds, "got back", dataLogin);

            if (!isDefined(dataLogin["token"])) {
                console.error("No token found in response!");
                return;
            }

            me.storeAndState("token", dataLogin.token, () => {
                me.handleStepperNext();
            });
        },
        error: function (data) {
            console.error("Failed login with", creds, "got back", data);

            me.props.enqueueSnackbar("Failed login. Double-check the credentials you entered.", {
                variant: 'error'
            });

            me.handleStepperFail();
        }
    });
}

export function increaseSyncCount() {
    this.setState({
        synchronization: {
            ...this.state.synchronization,
            count: this.state.synchronization.count + 1
        }
    });
}

export function toggleSync (toValue) {
    let {
        synchronization
    } = this.state;
    let {
        active
    } = synchronization;

    let to;
    if (typeof toValue === "boolean") {
        to = toValue;
    } else {
        to = !active;
    }

    this.setState({
        synchronization: {
            ...synchronization,
            active: to
        }
    }, console.warn("Toggled sync", to));
}

export function syncManual() {
    this.setState({
        synchronization: {
            active: false
        }
    }, console.log("Stopped auto-sync in favour of manual syncing"));
}

export function setInstances (newInstances, callback) {
    if (typeof newInstances !== "object") {
        console.error("Instances have wrong format:", typeof newInstances);
        this.logout();
        return;
    }
    this.storeAndState('instances', newInstances, () => {
        if (isDefined(callback))
            callback()
    });
}

export function chooseInstance(e) {
    let instUuid = e.target.getAttribute('data-instance-uuid');
    let instName = e.target.getAttribute('data-instance-nick');

    this.props.enqueueSnackbar("Instace '" + instName + "' was chosen");
    // console.log("Instance was chosen:", instName, instUuid);

    // todo use spread operator ...
    let thisRef = this;
    async.waterfall([
        function (callback) {
            thisRef.storeAndState("instance", instUuid, function () {
                callback(null);
            });
        },
        function (callback) {
            thisRef.storeAndState("instanceName", instName, function () {
                callback(null);
            });
        }
    ], function (err) {
        thisRef.setMessage({
            title: "Success!",
            content: "You have chosen '" + instName + "'",
            visible: false
        });
    });
}


export function setMessage (theMessage, callback) {
    // console.debug("Message set to", theMessage);
    this.setState({
        message: theMessage
    }, callback);
}

export function toggleMessageVisibility () {
    let message = {
        ...this.state.message
    };
    // let message = this.state.message;
    console.log("Toggling message visibility", message);

    if (!isDefined(message))
        return;

    if (!isDefined(message.visible))
        message["visible"] = true;
    else
        message["visible"] = !message.visible;

    this.setMessage(message);
}


export function setMedium(medium, callback) {
    console.debug("medium now:", medium);
    this.setState({
        medium: medium
    }, function () {
        if (isDefined(callback))
            callback();
    });
}

export function setChannels(data, callback) 
{
    // sort by parent
    var sorted = sortByKeys(data, 'parent', 'order');
    var channelsNested = getNestedChildren(sorted, 0);

    // console.debug("Setting channels to", channelsNested);

    this.setState({
        channels: channelsNested
    }, function () {
        if (isDefined(callback))
            callback();
    });
}

export function setJobs(data, callback) {
    // sort by parent
    // var sorted = sortByKeys(data, 'parent', 'order');
    // var channelsNested = getNestedChildren(sorted, 0);
    let newData = data;
    if(data.length > 3) {
        newData = data.slice(data.length - 3, data.length)
    }
    
    // console.debug("Setting jobs to", newData, "instead of", data);

    newData = newData.reverse();

    this.setState({
        jobs: newData
    }, function () {
        if (isDefined(callback))
            callback();
    });
}
