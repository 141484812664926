// eslint-disable-next-line no-unused-vars
import React from 'react';
import async from 'async';
import moment from 'moment';

import {
    isDefined
} from "../../helpers";


export function useFallbackMedium(cb) {
    let { user, medium } = this.state;
    let {
        streamUrl
    } = user;
    let newMedium = {
        ...medium
    };

    if(medium["type"] === "fallback") {
        cb();
        return;
    }

    console.info("Trying to figure fallback medium! ...");

    let me = this;
    async.waterfall([
        (callback) => {
            me.botGetLastStreamed((data) => {
                callback(null, data);
            })
        },
        (result, callback) => {
            if (isDefined(result) && result.length > 0) {
                console.info("The last manually streamed track(s):", result);

                // check for id or sthg
                let tracks = result[0]["tracks"];
                console.debug("tracks:", tracks);
                if(tracks.length > 0 && isDefined(tracks[0][0])) 
                {
                    // get last from array
                    let lastPl = tracks.pop();

                    if (lastPl && lastPl["date"] && medium["position"]) {
                        var momNow = moment();
                        var momPos = moment().subtract(medium["position"], "seconds").subtract(20, "seconds"); // TODO find / use real download duration
                        var momStart = moment(JSON.parse(lastPl["date"]));
                        // console.log("momNow", momNow.toDate());
                        // console.log("momPos", momPos.toDate());
                        // console.log("momStart", momStart.toDate());

                        var istDazwischen = momStart.isBetween(momPos, momNow);
                        if (istDazwischen) {
                            console.info("Fallback to last in history makes sense!!! ...");
                            newMedium["file"] = lastPl["url"];
                        } else {
                            console.info("Fallback to last in history makes no sense!!! ...");
                            newMedium["file"] = streamUrl;
                        }
                    } else {
                        newMedium["file"] = streamUrl;
                    }
                } else {
                    newMedium["file"] = streamUrl;
                }

                console.debug("Using fallback:", newMedium.file);
            }

            newMedium["type"] = "fallback";
            me.setMedium(newMedium, () => {
                callback(null, null);
            });
        }
    ], (error, result) => {
        if(isDefined(cb))
            cb();
    });
}

export function onPlayerError (errorCode) {
    console.error('[MEDIA] Error:', errorCode);
    const {
        medium, user
    } = this.state;

    const me = this;

    if(errorCode === 150) 
    {
        if (medium["type"] === "ytdl") 
        {
            me.props.enqueueSnackbar("Trying webstream...", {
                variant: 'warning'
            });

            me.setMedium({
                ...medium,
                file: user.streamUrl,
                type: "streamonly"
            }, () => {
                // me.useFallbackMedium();
            });
        } else {
            console.log(medium["type"]);
        }
    } 
    else 
    {
        me.props.enqueueSnackbar("Sorry, the player had an error!", {
            variant: 'error'
        });
    }
}

export function onPlayerCheck(a, b) {
    console.info('[MEDIA] check:', a, b);
    // let {
    //     medium
    // } = this.state;
    // let {
    //     position
    // } = medium;


    // let pl = this.getPlayer();
    // if (pl !== null)
    //     pl.seekTo(position);
    // else
    //     console.error("Cannot find player!", pl);
}

export function onDuration (duration) {
    let {
        medium
    } = this.state;
    let {
        position
    } = medium;

    console.debug("player medium duration:", duration, "sinusbot track position:", position);
    // var pl = this.getPlayer();
    // console.debug("player", pl);
    // if(isDefined(pl) && !pl.playing)
    //     pl.seekTo(medium.position);        
}

export function getPlayer() {
    let pl = this["mediaplayer"];
    
    if (isDefined(pl) && isDefined(pl.current) && isDefined(pl.current.playerInstance) && isDefined(pl.current.playerInstance.current)) {
        let inst = pl.current.playerInstance.current;
        console.debug("instance", inst);
        return inst;
    }
    return null;
}
