import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { isDefined } from '../../../helpers';

const styles = theme => ({
    root: {
        // padding: theme.spacing(2),
    },
    section: {
        // margin: theme.spacing(2) + 'px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    friend: {
        //margin: '0 ' + theme.spacing(2) + 'px ' + theme.spacing(2) + 'px 0 0',
        padding: theme.spacing(1) + 'px',
        backgroundColor: '#1F1F22',
        margin: theme.spacing(1) + 'px 0',
        border: '1px solid #111122',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '33%'
        },
        [theme.breakpoints.up('md')]: {
            width: '24%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '12%'
        },
        [theme.breakpoints.up('xl')]: {
            width: '8%'
        },
    },
    profile: {
        padding: theme.spacing(2)
    },
    fab: {
        
    }
});

const Game = (props) => {
    const { classes, game } = props;
    // let relation = (friend.relationship === 1 ? 'Bekannter' : 'Freund');

    return (
        <div title={JSON.stringify(game, 4)} className={classes.game}>
            <Typography variant="subtitle1">{game.appid}</Typography>
        </div>
    )
}

const StyledGame = withStyles(styles)(Game);

class GamesList extends React.Component 
{
    render() {
        const { classes, user } = this.props;

        let games = [];
        if(!isDefined(user.games) || !isDefined(user.games.games))
            return null;

        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>Games</Typography>

                <Divider variant="middle" />

                <div className={classes.section}>
                    {Array.isArray(games) && games.map((friendObject, i) => <StyledGame key={i} friend={friendObject} />)}
                </div>
            </div>
        )
    }
}

GamesList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GamesList);
