import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        //padding: theme.spacing(4),
        outline: 'none',
    },
    close: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
});

class RecipeModal extends React.Component {

    render() {
        const { classes, children } = this.props;

        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <div style={getModalStyle()} className={classes.paper}>
                        {children}
                        <Button className={classes.close} variant="contained" onClick={this.props.handleClose}>close</Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

RecipeModal.propTypes = {
    classes: PropTypes.object.isRequired
};

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(RecipeModal);

export default SimpleModalWrapped;

