import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import { Typography, Divider } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        // backgroundColor: theme.palette.secondary.light,
    },
    section: {
        margin: theme.spacing(2) + 'px 0',
    }
});

class InventoryValue extends React.Component {
    state = {
        
    };

    getInventoryValue = (appid) => {
        // let url = 'http://api.steampowered.com/ISteamUserStats/GetSchemaForGame/' +
        //     'v2/?key=' + this.state.apiKey + '&appid=' + this.state.appId;
        // console.log(url);
        // return url;
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>InventoryValue <small>0.1</small></Typography>
                <Typography variant="subtitle1" component="p" gutterBottom>Wenn das hier mal fertig ist, wird man damit herausfinden können, wieviel das eigene Steam-Inventar so wert ist.</Typography>
                
                <Divider variant="middle" />

                <div className={classes.section}>
                    <Fab disabled variant="extended" color="primary" onClick={this.getInventoryValue} aria-label="Search" className={classes.margin}>
                        <NavigationIcon className={classes.extendedIcon} />
                        Wert des Inventars ermitteln
                    </Fab>
                </div>   
            </div>
        )
    }
}

InventoryValue.propTypes = {
    classes: PropTypes.object.isRequired,
};

//export default App;
export default withStyles(styles)(InventoryValue);
