import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Link as RLink, Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import withRoot from '../withRoot';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// eslint-disable-next-line
import SyncWatch from '../components/syncwatch';
// eslint-disable-next-line
import KitchenMate from '../components/wondercook/index';
// eslint-disable-next-line
import ElevationFinder from '../components/gmap/index';
// eslint-disable-next-line
import SteamTools from '../components/steam/index';
import RainTime from '../components/raintime/index';
import DebugTool from '../components/debug/index';

import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from '../components/boundaries';
import { getTabValue } from '../components/steam/functions';
import Axios from 'axios';

//import { Helmet } from "react-helmet";

/* config */
require('dotenv').config();

// A MUI theme with custom primary and secondary color.
const styles = theme => ({
    '@global': {
        body: {
            transition: 'background-color .8s ease'
        }
    },
    root: {
        ...theme.mixins.gutters(),

        backgroundColor: 'rgba(0,0,0,.5)',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        paddingBottom: theme.spacing(2),
        flexGrow: 1,
        margin: '0 auto ' + theme.spacing(4) + 'px',
        [theme.breakpoints.down('sm')]: {
        },
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.breakpoints.values['lg']
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: theme.breakpoints.values['xl']
        },
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: `${theme.spacing(3)}px`,
    },
    footer: {
        background: 'transparent',
        opacity: 0.4,
        padding: theme.spacing(2) + 'px 0 0',
        textAlign: 'center',
        borderTop: '3px dashed rgba(255,255,255,.2)',
        // textShadow: '1px 1px 0 #aaa',
    },
    subText: {
        color: 'white'
    },
    divider: {
        margin: `${theme.spacing(2)}px 0`,
    },
});

// const GTAG = (
//         <Helmet>
//             <script async src="https://www.googletagmanager.com/gtag/js?id=UA-45583807-6"></script>
//             <script>
//                 window.dataLayer = window.dataLayer || [];
//         function gtag() {dataLayer.push(arguments); }
//                 gtag('js', new Date());
        
//                 gtag('config', 'UA-45583807-6');
//             </script>
//         </Helmet>
//     );

export const GA_TRACKING_ID = 'UA-45583807-6'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
    window.gtag('config', GA_TRACKING_ID, {
        page_location: url
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value
    })
}

function TabContainer(props) {
  return (
    <Typography component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Index extends Component {
    state = {
        user: {},
        session: {},
    };

    handleTabClick = (event, value) => {
        this.setState({ selectedTab: value });
        this.props.history.push('/syncwatch');
    }

    handleCallToRouter = (value) => {
        this.props.history.push(value);
    }

    handleRootState = (state) => {
        this.setState(state);
    }

    async storeSession(response) {
        // const { enqueueSnackbar } = this.props;
        var ses = { session: response.data };
        if (response.data.openId != null)
            ses["openId"] = response.data.openId;
        // else
        //     enqueueSnackbar("Session invalid!", {variant: "error"});
        await this.setState(ses, () => {
            // enqueueSnackbar("Session-Data set!");
        });
    }

    changePage = (event) => {
        let newTitle = event.target.innerText;
        document.title = newTitle;
        console.log(newTitle);
    }

    componentDidMount() {
        const me = this;

        Axios({
            method: "get",
            url: "/r3st/session",
            headers: {
                'Content-Type': "application/json",
            },
        })
        .then(function (response) {
            //console.log("response", response);
            me.storeSession(response);
        })
        .catch(function (error) {
            console.log("error", error);
        });
    }


    render() {
        const { classes, ...other } = this.props;

        return (
            <Router>
                <ErrorBoundary>
                    <SnackbarProvider autoHideDuration={1500} maxSnack={8} anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        dense: true,
                    }}>
                        <div className={classes.root}>
                            <Route
                                path="/"
                                render={({ location }) => {
                                    return (
                                        <Fragment>
                                            <AppBar position="static">
                                                <Tabs value={getTabValue(location, 1)} onChange={this.changePage}>
                                                    <Tab label="SyncWatch" value="" component={RLink} to="/" />
                                                    <Tab label="Steam" value="steam" component={RLink} to="/steam" />
                                                    <Tab label="KitchenMate" value="kitchen" component={RLink} to="/kitchen" />
                                                    <Tab label="Debug" value="debug" component={RLink} to="/debug" />
                                                    {/* <Tab label="ElevationFinder" value="elevation" component={RLink} to="/elevation" /> */}
                                                    {/* <Tab label="RainTime" value="raintime" component={RLink} to="/raintime" /> */}
                                                </Tabs>
                                            </AppBar>
                                            <TabContainer>
                                                <Switch>
                                                    {/* <Route path="/" exact={true} render={() => (
                                                        <Paper>
                                                            <Typography variant="h1">Home</Typography>
                                                            <Typography paragraph>yeahsn</Typography>
                                                        </Paper>
                                                    )} /> */}
                                                    <Route path="/" exact={true} component={SyncWatch} />
                                                    {/* <Route path="/syncwatch" component={SyncWatch} /> */}
                                                    <Route path="/steam">
                                                        <SteamTools handleRootState={this.handleRootState} {...other} {...this.state} />
                                                    </Route>
                                                    <Route path="/kitchen" component={KitchenMate} />
                                                    <Route path="/elevation" component={ElevationFinder} />
                                                    <Route path="/raintime" component={RainTime} />
                                                    <Route path="/debug">
                                                        <DebugTool handleRootState={this.handleRootState} {...this.state} />
                                                    </Route>
                                                </Switch>
                                            </TabContainer>
                                        </Fragment>
                                    )}
                                }
                            />

                            <div className={classes.footer}>
                                <Typography className={classes.subText} variant="caption">
                                    This project is just a collection of apps that hopefully make some things easier.
                                </Typography>
                                <Typography className={classes.subText} variant="caption">
                                    <small>made</small> Dec. 2016 - now; <Link href="http://www.v1nce.de" color="secondary" target="_blank">vincolus</Link>
                                </Typography>
                            </div>
                        </div>
                    </SnackbarProvider>
                </ErrorBoundary>
            </Router>
        );
    }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Index));
