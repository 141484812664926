import React from 'react';
import { Typography } from '@material-ui/core';
import { isDefined } from '../../helpers';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    message: {
        background: 'rgba(255,255,255,.1)',
        margin: '0 auto',
        width: 540,
        maxWidth: '95%',
        textAlign: 'center',
        padding: theme.spacing(2),
        borderRadius: 2,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
    }
});

function MessageFunc(props) {
    if (!isDefined(props))
        return null;

    const { classes } = props;

    return (
        <div className={classes.message}>
            <Typography variant="h3">{props.title}</Typography>
            {props.content}
        </div>
    );
}

const Message = withStyles(styles)(MessageFunc);

export {
    Message
}


// function MessageFunc(props) {
//     if (isDefined(props))
//         return null;

//     const { classes } = props;

//     return (
//         <div className={classes.message}>
//             <Typography variant="h3">{props.title}</Typography>
//             {props.content}
//         </div>
//     );
// }

// const Message = withStyles(styles)(MessageFunc);
// export {
//     Message
// }
