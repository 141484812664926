import React from 'react';
import { isDefined, parseJson } from '../../helpers';
import { Message } from './message.jsx';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Input, Typography } from '@material-ui/core';
import { isArray, isObject } from 'util';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

function InstanceSelection(props) 
{
    const { classes, instances, instance, handleUserStateChange } = props;

    if(!isDefined(instances)) {
        return (
            <Message type="error" content={<Typography variant="h6">No instances of sinusbot to select from!<br />Please report this to the administrator!</Typography>} />
        );
    }

    let allInstances = parseJson(instances);
    if(!isDefined(allInstances) || typeof allInstances !== "object") {
        console.log("Instances have the wrong format:", typeof allInstances);
        return (
            <Message type="error" content={<Typography variant="h6">The instances seems to have the wrong format!</Typography>} />
        );
    }

    console.debug("Mapping instances", allInstances);
    let selectionMarkup;
    if(isArray(allInstances) || isObject(allInstances)) {
        selectionMarkup = Object.keys(allInstances).map((key, i) => {
            if(key === "status")
                return null;

            let instance = allInstances[key];
            return (
                <MenuItem key={i} value={instance.uuid}>
                    <em>{instance.nick}</em>
                </MenuItem>
            )
        });
    }

    return (
        <div className='dropdown controls' style={{ display: 'inline-block' }}>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="bot-instance">Choose a bot</InputLabel>
                <Select
                    value={instance}
                    onChange={handleUserStateChange}
                    input={<Input name="instance" id="bot-instance" />}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>

                    {selectionMarkup}
                </Select>
                <FormHelperText>Some important helper text</FormHelperText>
            </FormControl>
        </div>
    );
}

// export default InstanceSelection;
InstanceSelection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InstanceSelection);
