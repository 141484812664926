import { getViaAjax, parseJson, isDefined, removeURLParameter, getHeaders } from "../../helpers";
import async from 'async';
import { isFunction } from "util";

export function fetchDefaultBotId(callback) {
    let me = this;

    getViaAjax({
        url: '/api/v1/botId',
        method: 'GET'
    }, {
        success: data => {
            let dataBot = parseJson(data);

            let hasId = false;
            if (isDefined(dataBot) && isDefined(dataBot.defaultBotId)) 
            {
                hasId = true;
                // console.debug("> fetchDefaultBotId via ajax returned", data, "hasId", hasId);
                me.storeAndState("botId", dataBot.defaultBotId, () => callback(hasId));
            } 
            else 
            {
                console.error("Got an answer. But there was not bot?", data);
            }
        },
        error: error => {
            if (isDefined(callback))
                callback(false);
            else
                console.error("no cb found; fetching defaultBotId errored", error);
        }
    });
}

export function authenticate(cb) {
    let me = this;
    let newUrl = window.location.href;

    async.waterfall([
        function (callback) {
            // consume tokenid (from url)
            newUrl = removeURLParameter(newUrl, 'tok');
            me.getFromUrlStoreAndState('tok', 'token', value => {
                callback(null, value);
            });
        },
        function (result, callback) {
            // consume instId (from url)
            newUrl = removeURLParameter(newUrl, 'instId');
            me.getFromUrlStoreAndState('instId', 'instance', value => {
                callback(null, result);
            });
        },
        function (result, callback) {
            // refresh window (if necessary)
            if (newUrl !== window.location.href) {
                console.log("Reloading page, because", newUrl, "is not like", window.location.href);
                window.location.href = newUrl;
                return;
            }

            callback(null, result);
        },
    ], function (error, result) {
        if (isDefined(error)) {
            console.error("authenticate: error >", error);
            if (isDefined(cb))
                cb(false);
        } else {
            if (!isDefined(result)) {
                console.warn("authenticate: no token loaded, login mask required");
                if (isDefined(cb))
                    cb(result);
            } else {
                // set default ajax headers from token
                var tmpNet = {
                    ...me.state.net
                };
                tmpNet.headers = getHeaders(me.state.user.token);

                me.setState({
                    net: tmpNet
                }, () => {
                    // console.debug("<< authenticate: validated Headers > set to", me.state.net.headers);
                    if (isDefined(cb))
                        cb(true);
                });
            }

        }
    });
}

export function fetchInstanceInfos(callback) {
    let newBearer = this.state.user.token;
    let newHeaders = getHeaders(newBearer);
    console.debug("Setting headers and fetching all available instances with", newHeaders);

    this.setState({
            net: {
                ...this.state.net,
                headers: newHeaders
            }
        },
        function () {
            let me = this;

            // Instances - List
            // Permission: none
            getViaAjax({
                url: '/api/v1/bot/instances',
                method: 'GET',
                headers: me.state.net.headers
            }, {
                success: function (data) {
                    console.debug("> Got instances =>", data);
                    me.props.enqueueSnackbar("Retrieved and cached " + data.length + " instances for selection.");

                    me.setInstances(data, () => {
                        console.info("Instances set!");
                        if (isDefined(callback))
                            callback(true);
                    });
                },
                error: function (data) {
                    console.error("Error getting instances. data =>", data);

                    // check for error
                    me.setMessage({
                        title: "Not authorized!",
                        content: "This user is missing the required permissions to manage instances.",
                    });

                    if (isDefined(callback))
                        callback(false);
                }
            });
        }
    );
}

export function botPlay(trackUuid) {
    let {
        medium
    } = this.state;
    let pauseOrPlayById;
    
    console.log("starting botPlay with", typeof trackUuid, trackUuid);

    if(isDefined(trackUuid)) {
        switch(typeof trackUuid) {
            case 'boolean': 
                if(trackUuid === true && isDefined(medium.uuid)) {
                    pauseOrPlayById = 'play/byId/' + medium.uuid;
                } else {
                   pauseOrPlayById = 'pause';
                }
                break;
            case 'string': 
                pauseOrPlayById = 'play/byId/' + trackUuid;
                break;
            default: break;
        }
    } else {
        if (isDefined(medium.uuid))
            pauseOrPlayById = 'play/byId/' + medium.uuid;
    }

    let instAction = '/api/v1/bot/i/' + this.state.user.instance + '/' + pauseOrPlayById;
    console.log("instAction", instAction);

    // Playback - Playback a file
    // Permission: PLAYBACK
    let diss = this;
    getViaAjax({
        url: instAction,
        method: 'POST',
        data: JSON.stringify({
            instanceId: diss.state.user.instance,
            id: trackUuid
        }),
        headers: diss.state.net.headers
    }, {
        success: (data) => {
            if(!isDefined(data.success))
                console.error("failed playback! url might be wrong...");
            else {
                console.info("success playback!");
            }
        }
    });
}

export function botStop(callback) {
    // Playback - Stop playback
    // Permission: PLAYBACK
    let diss = this;
    getViaAjax({
        url: '/api/v1/bot/i/' + diss.state.user.instance + '/stop',
        method: 'POST',
        headers: diss.state.net.headers
    }, {
        success: function (data) {
            // diss.botSeek(0, callback);
            diss.props.enqueueSnackbar("Playback stopped!", {
                variant: 'info'
            });
        }
    });
}

export function getStreamToken(callback) {
    // Streaming - Get a token for the WebStream
    // Permission: LOGIN
    let diss = this;
    getViaAjax({
        url: '/api/v1/bot/i/' + diss.state.user.instance + '/streamToken',
        method: 'POST',
        headers: diss.state.net.headers
    }, {
        success: function (data) {
            if (isDefined(callback))
                callback(data);
        },
        error: function (error) {
            diss.props.enqueueSnackbar("Got no stream token!", {
                variant: 'error'
            });

            if (isDefined(callback))
                callback(null);
        }
    });
}

export function getAudioStream(callback, token) {
    let {
        streamToken,
        instance
    } = this.state.user;

    let diss = this;

    if (isDefined(token))
        streamToken = token;

    // Streaming - Get audio stream
    // requires EnableWebStream = true
    getViaAjax({
        url: 'http://127.0.0.1:8087/api/v1/bot/i/' + instance + '/stream/' + streamToken,
        data: JSON.stringify({
            instanceId: instance,
            token: streamToken
        }),
        method: 'POST',
    }, {
        success: function (data) {
            diss.props.enqueueSnackbar("Connected to audio stream!", {
                variant: 'info'
            });

            if (isDefined(callback))
                callback(data);
        },
        error: function (error) {
            diss.props.enqueueSnackbar("Failure getting audio stream!", {
                variant: 'error'
            });

            if (isDefined(callback))
                callback(error);
        }
    });
}

export function botNext(callback) {
    // Playlists - Next
    // Permission: PLAYBACK
    let diss = this;
    getViaAjax({
        url: '/api/v1/bot/i/' + diss.state.user.instance + '/playNext',
        method: 'POST',
        headers: diss.state.net.headers
    }, {
        success: function (data) {
            // diss.botSeek(0, callback);
            diss.props.enqueueSnackbar("Playing next track!", {
                variant: 'info'
            });
        }
    });
}

export function botPrev(callback) {
    // Playlists - Next
    // Permission: PLAYBACK
    let diss = this;
    getViaAjax({
        url: '/api/v1/bot/i/' + diss.state.user.instance + '/playPrevious',
        method: 'POST',
        headers: diss.state.net.headers
    }, {
        success: function (data) {
            // diss.botSeek(0, callback);
            diss.props.enqueueSnackbar("Playing previous track!", {
                variant: 'info'
            });
        }
    });
}

export function botSeek(posPercent, callback) {
    let diss = this;
    let seekPos = posPercent >= 0 && posPercent <= 100 ? posPercent : 0;

    // Playback - Seek
    // Permission: PLAYBACK
    getViaAjax({
        url: '/api/v1/bot/i/' + diss.state.user.instance + '/seek/' + seekPos,
        method: 'POST',
        headers: diss.state.net.headers
    }, {
        success: data => {
            console.log("-> seeked to: " + seekPos);
            if (typeof callback === "function")
                callback();
        }
    });
}

export function botAddJob(jobUrl, callback) {
    // Upload_and_Download - Add job
    // Permission: UPLOAD_FILE
    let diss = this;
    getViaAjax({
        url: '/api/v1/bot/jobs',
        method: 'POST',
        data: {
            url: jobUrl
        },
        headers: diss.state.net.headers
    }, {
        success: function (data) {
            // diss.botSeek(0, callback);
            diss.props.enqueueSnackbar("Job successfully added!", {
                variant: 'success'
            });
        },
        error: function (err) {
            diss.props.enqueueSnackbar("Job could not be added: " + jobUrl, {
                variant: 'error'
            });
        }
    });
}

/* custom commands */
export function botAddStreaming(jobUrl, callback) {
    // Upload_and_Download - Add job
    // Permission: UPLOAD_FILE
    let diss = this;

    let jsonData = JSON.stringify({
        "url": jobUrl
    });

    getViaAjax({
        url: '/api/v1/b/' + diss.state.user.botId + '/i/' + diss.state.user.instance + '/event/youtube-play',
        method: 'POST',
        headers: diss.state.net.headers,
        data: jsonData,
    }, {
        success: function (data) {
            console.debug(data);
            // TODO handle more errors

            diss.props.enqueueSnackbar("Job successfully added!", {
                variant: 'success'
            });
        },
        error: function (err) {
            diss.props.enqueueSnackbar("Job could not be added: " + jobUrl, {
                variant: 'error'
            });
        },
        always: function(data) {
            if(isFunction(callback))
                callback(data);
        }
    });
}

export function botGetLastStreamed(callback) {
    // Upload_and_Download - Add job
    // Permission: UPLOAD_FILE
    let diss = this;

    getViaAjax({
        url: '/api/v1/b/' + diss.state.user.botId + '/i/' + diss.state.user.instance + '/event/last-tracks',
        method: 'POST',
        data: '{}',
        headers: diss.state.net.headers
    }, {
        success: function (data) {
            // console.debug("Last played:", data, typeof data);
            if (isFunction(callback))
                callback(data);
        },
        error: function (err) {
            diss.props.enqueueSnackbar("Info about the last played track cannot be fetched", {
                variant: 'error'
            });
            if (isFunction(callback))
                callback(null);
        }
    });
}
