import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import IconSteam from '../img/icon-steam.png';
import IconOrigin from '../img/icon-origin.png';
import { isDefined } from '../../../helpers';
import GameClaimCard from './gameClaimCard';
import GameModal from '../../common/SimpleModal';
import { Button } from '@material-ui/core';
import GameInfos from './GameInfos';

const styles = theme => ({
  root: {
    // ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  results: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '0 -' + theme.spacing(1) + 'px 0'
  },
  media: {
    height: 'auto'
  },
  resultsItem: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },
    [theme.breakpoints.up('md')]: {
      width: '33%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '25%'
    },
    [theme.breakpoints.up('xl')]: {
      width: '12.5%'
    },
  }
});


class HumbleList extends React.Component 
{
  state = {
    open: false,
    selected: {}
  }

  openModal = async (item) => {
    console.log("setting selected", item);
    return new Promise((res, rej) => {
      this.setState({
        selected: item,
        open: true
      }, res);
    });
  }

  stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  claimKey = (item) => {
    const { selected } = this.state;
    const { openId } = this.props;
    
    this.openModal(item)
    .then(() => {
      console.log("selected", selected);
      let keyId = selected.key;
      fetch("/r3st/claimkey/" + openId + "/" + keyId)
        .then(response => response.json())
        .then((res, err) => {
          if (isDefined(err))
            console.log("error with gamekey json:", err);

          console.debug("successfully claimed key", keyId);
          // TODO set state?
        });      
    });
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  getIcon = (type) => {
    let icon;
    if (isDefined(type)) {
      switch (type) {
        case 'steam':
          icon = IconSteam;
          break;
        case 'origin':
          icon = IconOrigin;
          break;
        default: break;
      }
    }
    return icon;
  }


  render() {
    const { classes, games, ...other } = this.props;
    let { selected, open } = this.state;

    // fallback for undefined items
    let gamesJson = games;
    if (!isDefined(games))
      gamesJson = {
        count: 0,
        countUsed: 0,
        games: []
      };

    // media
    let media = null;
    if (isDefined(selected.image)) {
      media = (<CardMedia
        component="img"
        alt={selected.name}
        className={classes.media}
        height={140}
        image={selected.image}
        title={selected.name}
      />);
    }

    let gamesArr = gamesJson.games;

    return (
      <div className={classes.root}>
        <Typography variant="h5" component="h3">
          Wir haben {gamesJson.count} Keys in der Datenbank.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Davon sind {gamesJson.countUsed} bereits vergriffen!
        </Typography>

        <div className={classes.results}>
          {isDefined(gamesArr) && gamesArr.length > 0 && gamesArr.map((item, i) => {
            // Return each element. Also pass key     
            return (
              <div key={i} className={classes.resultsItem}>
                <GameClaimCard
                  {...other} 
                  {...item} 
                  icon={this.getIcon(item.type)} 
                  openModal={() => {this.openModal(item)}} 
                  claimKey={() => {this.claimKey(item)} }
                />
              </div>
            )
          })}
        </div>

        <GameModal handleClose={this.handleClose} open={open}>
          {isDefined(selected.name) && (
            <GameInfos getIcon={this.getIcon} {...this.state} {...other} />
          )}

          {isDefined(selected.confirm) && (
            <div>
              <Button>Claim this key</Button>
            </div>
          )}
        </GameModal>
      </div>
    );
  }
}

HumbleList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(HumbleList);
