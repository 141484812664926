import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { isDefined } from '../../helpers';
// import RecipeDetail from './recipeDialog';

const styles = theme => ({
    card: {
        // maxWidth: 345,
    },
    media: {
        // object-fit is not supported by IE 11.
        objectFit: 'cover',
    },
});

function RecipeCard(props) {
    const { classes, updateSelected } = props;
    let media;
    if(isDefined(props.image)) {
        media = (<CardMedia
            component="img"
            alt={props.name}
            className={classes.media}
            height="140"
            image={props.image}
            title={props.name}
        />);
    }

    return (
        <Card className={classes.card}>
            <CardActionArea onClick={() => { updateSelected(props) }}>
                {media}
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.name}
                    </Typography>
                    <Typography component="p">
                        {props.description}
                    </Typography>
                    {/* <Typography component="pre">
                        {JSON.stringify(props)}
                    </Typography> */}
                </CardContent>
            </CardActionArea>
            <CardActions>
                {/* <Button size="small" color="primary">
                    Share
                </Button> */}
                {/* <Button size="small" color="primary">
                    Rezept ansehen
                </Button> */}
                <Button data-title={props.title} data-content={props.content} onClick={() => { updateSelected(props)}}>
                    Rezept ansehen
                </Button>
            </CardActions>
        </Card>
    );
}

RecipeCard.propTypes = {
    classes: PropTypes.object.isRequired
    // theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RecipeCard);
