import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import KeyIcon from '@material-ui/icons/VpnKey';
// import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getInitials, isDefined } from '../../../helpers';

const styles = theme => ({
    card: {
        maxWidth: 400,
        background: 'rgba(255,255,255,.2)',
        border: '1px solid rgba(255,255,255,.3)',
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(255,255,255,.28)',
        }
    },
    cardClaimed: {
        backgroundColor: 'rgba(255,75,75,.2) !important',
        borderColor: 'rgba(255,75,75,.3) !important',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
});

class GameClaimCard extends React.Component 
{
    state = { expanded: false };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        const { classes, openModal, claimKey, isSignedIn } = this.props;
        let { name, icon, instruction, description, image, date_published, claimStatus, claimer } = this.props;

        // get initials only
        let initials = getInitials(name);

        let avatarOrIcon = (
            <Avatar src={icon} />
        );
        
        if(!isDefined(icon)) {
            avatarOrIcon = (
                <Avatar aria-label="Recipe" className={classes.avatar} onClick={() => { openModal(this.props) }}>
                    {initials}
                </Avatar>
            );
        }

        // set css class depending on whether its claimed
        let claimed = claimStatus === "claimed";

        let cardClass = classes.card;
        if(claimed) {
            cardClass += " " + classes.cardClaimed;
        }

        return (
            <Card className={cardClass}>
                <CardHeader
                    avatar={avatarOrIcon}
                    // action={
                    //     <IconButton>
                    //         <MoreVertIcon />
                    //     </IconButton>
                    // }
                    title={name}
                    subheader={date_published}
                />

                {isDefined(image) && (
                    <CardMedia
                        className={classes.media}
                        image={image}
                        title={name}
                        onClick={() => { openModal(this.props) }}
                    />
                )}

                {/* {isDefined(description) && ( */}
                    <CardContent>
                        <Typography component="p">
                            {description}
                        </Typography>
                    </CardContent>
                {/* )} */}

                <CardActions className={classes.actions}>
                    {/* <IconButton aria-label="Add to favorites">
                        <FavoriteIcon />
                    </IconButton> */}
                    <IconButton title="Fullscreen" onClick={() => { openModal(this.props) }}>
                        <FullScreenIcon />
                    </IconButton>
                    {isSignedIn && (
                        <IconButton disabled={claimed} title="Claim key" onClick={() => { claimKey(this.props) }}>
                            <KeyIcon />
                        </IconButton>
                    )}
                    {claimed && (
                        <Typography variant="caption" component="p">claimed by {claimer}</Typography>
                    )}
                    
                    {isDefined(instruction) && (
                        <IconButton
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                            aria-label="Show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    )}
                </CardActions>

                {isDefined(instruction) && (
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography paragraph>Hinweis:</Typography>
                            <Typography paragraph>{instruction}</Typography>
                        </CardContent>
                    </Collapse>
                )}

            </Card>
        );
    }
}

GameClaimCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GameClaimCard);