import React from 'react';
import async from 'async';
import { isDefined, getViaAjax, isYoutubeVideo } from '../../helpers';
// import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
// import { withSnackbar } from 'notistack';

const styles = {
    root: {
        flexGrow: 1,
    },
};

class Syncer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            init: true,
            posPlayer: 0,
            posBot: 0,
            posSync: 0
        };
    }

    componentDidMount() {
        this.startTimer();
        // this.timer2 = setInterval(this.progress.bind(this), 500);
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        console.warn("unmounting syncer. clearing timers.");
        clearInterval(this.timer2);
        this.stopTimer();
    }

    progress = () => {
        // const { posSync } = this.state;
        // let { interval, medium, player } = this.props;


        // if (!isDefined(player) || !isDefined(player.playerInstance) || !isDefined(player.playerInstance.current))
        //     return;

        // let newPosSync = Math.min(posSync + interval / 500, 100);

        // let duration = medium.duration;
        // let newPosPlayer = player.playerInstance.current.getCurrentTime() / duration * 100;
        // let newPosBot = medium.position / duration * 100;

        // // this.setState({ 
        // //     posSync: newPosSync,
        // //     posPlayer: newPosPlayer,
        // //     posBot: newPosBot
        // // });
        // if (newPosSync >= 100) {
        //     this.setState({
        //         posSync: 0,
        //         posPlayer: 0,
        //         posBot: 0
        //     });
        // } else {
        //     // let result = Math.min(newPosSync + 100 / (interval / 20), 100);
        //     this.setState({
        //         posSync: newPosSync,
        //         posPlayer: newPosPlayer,
        //         posBot: newPosBot
        //     });
        // }
    };

    startTimer = () => {
        let { interval, active } = this.props;

        console.log("Sync Timer started with interval", interval);
        this.timer(() => {
            var intervalId = setInterval((me) => {
                if (active !== true) {
                    me.stopTimer();
                } else {
                    me.timer();
                }
            }, interval, this);

            // store intervalId in the state so it can be accessed later:
            this.setState({ intervalId: intervalId });
        });
    }

    stopTimer = () => {
        clearInterval(this.state.intervalId);
    }

    hasHeaders = () => {
        let { headers } = this.props;
        return isDefined(headers) && isDefined(headers['Authorization']);
    }

    hasPlayerProblems = () => {
        const { player } = this.props;

        if (!isDefined(player) || !isDefined(player.current) || !isDefined(player.current.playerInstance) || !isDefined(player.current.playerInstance.current))
            return true;
        else {
            // const pl = player.current.playerInstance.current;
            // console.warn("Checking if player has problems", pl, pl.getDuration());
        }

        return false;
    }

    timer = (cb) => {
        const { hasInstance, headers, instance, increase, setChannels, setJobs, medium, useFallbackMedium, slideoutOpen } = this.props;
        const { init } = this.state;

        if (!hasInstance() || !this.hasHeaders()) {
            console.error("Skipping due to no headers!");
            return;
        }

        let me = this;
        async.waterfall([
            (callback) => {
                // TODO make optional: fetch status
                getViaAjax({
                    url: '/api/v1/bot/i/' + instance + '/status',
                    type: 'GET',
                    headers: headers
                }, {
                    success: (data) => {
                        me.setParentMedium(data, () => {
                            callback(null, null);
                        });
                    },
                    error: (data) => {
                        callback("Could not fetch status!", null);
                    }
                });
            },
            (result, callback) => {
                if (!slideoutOpen && !init) {
                    callback(null, null);
                    return;
                }

                // TODO make optional: fetch channels
                getViaAjax({
                    url: '/api/v1/bot/i/' + instance + '/channels',
                    type: 'GET',
                    headers: headers
                }, {
                    success: (data) => {
                        setChannels(data, () => {
                            callback(null, null);
                        });
                    },
                    error: (data) => {
                        callback("Could not fetch channels!", null);
                    }
                });
            },
            (result, callback) => {
                if (!slideoutOpen && !init) {
                    callback(null, null);
                    return;
                }

                // TODO optional: fetch jobs
                getViaAjax({
                    url: '/api/v1/bot/jobs',
                    type: 'GET',
                    headers: headers
                }, {
                    success: (data) => {
                        setJobs(data, () => {
                            callback(null, null);
                        });
                    },
                    error: (data) => {
                        callback("Could not fetch jobs!", null);
                    }
                });
            },
            (result, callback) => {
                // disable init if needed
                if (init) {
                    this.setState({
                        init: false
                    }, () => {
                        callback(null, null);
                    })
                } else {
                    callback(null, null);
                }
            },
            (result, callback) => {
                // fallback if youtube video possible and not playing already
                if (medium["playing"] === true) {
                    if (medium["type"] === "stream") {
                        useFallbackMedium(() => {
                            callback(null, null);
                        });
                        // callback(null, null);
                    } else 
                    if (me.hasPlayerProblems()) {
                        console.error("There is a problem with the player", medium);

                        if (medium["type"] !== "fallback") {
                            me.props.enqueueSnackbar(
                            (<div>
                                There is a problem with the player. Trying fallback!
                            </div>), {
                                variant: 'warning'
                            });

                            useFallbackMedium(() => {
                                callback(null, null);
                            });
                        } else {
                            callback(null, null);
                        }
                    } else {
                        callback(null, null);
                    }
                } else {
                    callback(null, null);
                }
            }
        ], (error, result) => {
            if (isDefined(error)) {
                console.error("authenticate: error >", error);
                me.props.enqueueSnackbar(error, {
                    variant: 'error'
                });
                me.stopTimer();
                if (isDefined(cb))
                    cb(false);
            } else {
                increase();
                if (isDefined(cb))
                    cb(result);
            }
        });
    }

    setParentMedium = (statusData, callback) => {
        console.debug("status:", statusData);

        let { medium, setMedium, player, user } = this.props;
        let { streamUrl } = user;
        let { currentTrack, position, playing, playlistTrack } = statusData;

        const maxDifference = 1000 / 1000;

        // copy old medium values
        let tmpMedium = {};
        if(isDefined(medium))
            tmpMedium = {...medium};

        // console.debug("pos:", position, "playing:", playing);
        let newPosition = ((position) / 1000);


        if(playing === true) 
        {
            let newDuration = currentTrack.duration / 1000;
            let newUUID = currentTrack.uuid;
            let newTitle = currentTrack.title;

            if (medium["playing"] === playing && newUUID === medium.uuid && newTitle === medium.title) 
            {
                // Track did not change! 
                if (medium["type"] === "temp" || medium["type"] === "streamonly" || (medium["type"] === "fallback" && !isYoutubeVideo(medium["file"]))) {
                    if (isDefined(callback))
                        callback();
                    return;
                }

                // exit if player not ready (yet)
                if (!isDefined(player) || !isDefined(player.current) || !isDefined(player.current.playerInstance)) {
                    if (isDefined(callback))
                        callback();
                    return;
                }

                // check for time differences
                let inst = player.current.playerInstance;
                let posPlayer = inst.current.getCurrentTime(); // Returns the number of seconds that have been played

                if (!isDefined(posPlayer)) {
                    if (isDefined(callback))
                        callback();
                    return;
                }

                let diff = Math.abs(newPosition - posPlayer);
                if (diff >= maxDifference || playing !== medium["playing"]) {
                    console.warn("Diff [", diff, "] -> syncing player from", posPlayer, "to", newPosition);
                    inst.current.seekTo(newPosition);
                    // this.props.enqueueSnackbar("Trying to synchronize now...");
                    //this.props.player.playerInstance.current.play();
                    if (isDefined(callback))
                        callback();
                    return;
                } 
                else 
                {
                    // console.info("In Sync!");
                    if (isDefined(callback))
                        callback();
                    return;
                }
            } 
            else 
            {
                // Track did change! 
                let nameOrUrl = currentTrack.filename || currentTrack.url;

                // data-model
                tmpMedium = {
                    artist: currentTrack.artist,
                    title: currentTrack.title,
                    file: nameOrUrl || "",
                    start: position,
                    playing: playing,
                    playlistTrack: playlistTrack,
                    position: position,
                    duration: newDuration,
                    thumbnail: currentTrack.thumbnail ? '/cache/' + currentTrack.thumbnail : '',
                    uuid: currentTrack.uuid,
                    type: currentTrack.type || ''
                };

                if (!isDefined(nameOrUrl)) {
                    console.debug("no nameOrUrl defined. using stream.", nameOrUrl);
                    // fallback for youtube
                    tmpMedium["file"] = streamUrl;
                    tmpMedium["type"] = "stream";
                } else {
                    if (isYoutubeVideo(nameOrUrl) && tmpMedium["type"] !== "fallback") {
                        tmpMedium["type"] = "ytdl";
                    }

                    var reg = new RegExp(/.*\..{2,3}$/i);
                    if (reg.test(nameOrUrl)) {
                        console.debug("matches filename-pattern. using stream. nameOrUrl:", nameOrUrl);
                        tmpMedium["file"] = streamUrl;
                        tmpMedium["type"] = "streamonly";
                    }
                }

                // song changed
                console.warn("Song change from", medium, "to", tmpMedium);

                // set position for later calculations
                // tmpMedium["position"] = newPosition;

                setMedium(tmpMedium, () => {
                    if (isDefined(callback))
                        callback();
                });
                return;
            }
        } else 
        {
            // console.debug("Nothing playing");

            if (medium["info"] !== true) 
            {
                // nothing playing
                tmpMedium = {
                    artist: currentTrack.artist || "Nothing",
                    title: currentTrack.title || "Playing",
                    info: true,
                    uuid: currentTrack.uuid,
                    type: currentTrack.type,
                    position: 0,
                    playing: false
                };

                setMedium(tmpMedium, () => {
                    if (isDefined(callback))
                        callback();
                });
                return;

            } else 
            {
                // console.debug("Nothing has changed");
                if (isDefined(callback))
                    callback();
                return;
            }
        }
    }


    render() {
        // let { classes } = this.props;
        return null;
        // return (
        //     <div className={classes.root}>
        //         <LinearProgress variant="determinate" value={this.state.posPlayer} />
        //         <LinearProgress variant="determinate" value={this.state.posBot} />
        //         <LinearProgress variant="determinate" value={this.state.posSync} />
        //     </div>
        // );
    }
}

Syncer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(Syncer));
