import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ImageIcon from '@material-ui/icons/Image';
// import WorkIcon from '@material-ui/icons/Work';
// import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { isDefined } from '../../../helpers';

const styles = theme => ({
    padded: {
        padding: theme.spacing(2),
        listStyle: 'none'
    },
});

class GameInfos extends React.Component {
    render() 
    {
        const { classes, selected, media, getIcon } = this.props;

        return (
            <div>
                {media}
                <div className={classes.padded}>
                    {/* Name */}
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                {/* {isDefined(selected.image) && ( <img src={selected.image} /> )}
                                {!isDefined(selected.image) && ( <ImageIcon /> )} */}
                                <img alt={'key for ' + selected.type} title={'key for ' + selected.type} src={getIcon(selected.type)} width={'100%'} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={selected.name} secondary="Jan 9, 2014" />
                    </ListItem>
                    <Divider variant="inset" component="li" />

                    {/* Description */}
                    {isDefined(selected.description) && (
                        <div>
                            <ListItem>
                                <ListItemText primary={'Description'} secondary={selected.description} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    )}

                    {/* Key */}
                    <ListItem>
                        <ListItemText primary={'Key'} secondary={this.props.isSignedIn ? selected.key : 'please sign in first'} />
                    </ListItem>
                    <Divider variant="inset" component="li" />

                    {/* Instruction */}
                    {isDefined(selected.instruction) && (
                        <div>
                            <ListItem>
                                <ListItemText primary={'Instructions'} secondary={(
                                    <Typography component="pre">{JSON.stringify(selected.instruction, null, 4)}</Typography>
                                )} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    )}

                    {isDefined(selected.claimStatus) && (
                        <div>
                            <ListItem>
                                <ListItemText primary={'Claim Status'} secondary={selected.claimStatus + ' by ' + selected.claimer} />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

GameInfos.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(GameInfos);
