import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isDefined, getViaAjax, parseJson } from '../../helpers';
import { Paper, MenuItem, NoSsr } from '@material-ui/core';
import Select from 'react-select';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    root: {
        flexGrow: 1,
        // height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});


function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class SinusbotSearchClass extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            query: null,
            results: [],
            visible: false
        };
    }

    setActive = () => {
        this.setState({
            visible: true
        });
    }

    setInactive = (e) => {
        // TODO
        let targ = e.relatedTarget;
        if (isDefined(targ) && targ.tagName === "A")
            return;
        else
            console.debug("target tag was", targ);

        this.setState({
            visible: false
        });
    }

    setResults = (sinusbotJson) => {
        let mapped = sinusbotJson.map((result, i) => ({
            value: result.uuid,
            label: result.artist + ' ' + result.title,
        }));

        this.setState({
            results: mapped
        });
    }

    showResults = () => {
        let query = this.state.query;
        if (query.length < 2 || !isDefined(query)) {
            // this.setResults([]);
            return false;
        }

        var thisRef = this;

        clearTimeout(this.state.timeout);
        let myTimeout = setTimeout(() => {
            console.log("Showing results for: " + query);

            getViaAjax({
                url: '/api/v1/bot/files',
                method: 'GET',
                headers: this.props.headers
            }, {
                success: function (data) {
                    let dataObj = parseJson(data);

                    // filter
                    let results = dataObj.filter(result => {
                        let searchThis = result.artist + " " + result.title + " " + result.file;
                        return searchThis.toLowerCase().includes(query.toLowerCase());
                    });

                    console.debug("[Sinusbot Search] showing", results.length, "of", dataObj.length, "items");

                    thisRef.setResults(results);
                },
                error: function (data) {
                    thisRef.setResults([]);                
                }
            });
        }, 500);

        this.setState({timeout: myTimeout});
    }

    handleQueryChange = name => value => {
        if (!isDefined(value))
            return;
        // console.debug("handling change of", name, value);

        this.setState({
            [name]: value,
        }, () => {
            if (name === "query") {
                this.showResults();
            }
        });
    };

    handleSelected = name => trackData => {
        console.log("handling selection of", name, trackData);
        this.setState({
            [name]: trackData.label,
        }, () => {
            if (name === "query") {
                this.props.botPlay(trackData.value);
            }
        });
    };

    render()
    {
        let { results } = this.state;
        const { classes, theme } = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        return (
            <div className={classes.root}>
                <NoSsr>
                    <Select
                        classes={classes}
                        styles={selectStyles}
                        components={components}
                        options={results}
                        value={this.state.query}
                        onChange={this.handleSelected('query')}
                        onInputChange={this.handleQueryChange('query')}
                        // placeholder={this.state.query}
                        // isClearable
                    />
                    {/* <Input type="text" placeholder={'...'} onChange={this.showResults} onFocus={this.setActive} />
                    <Paper component="div" hidden={!visible} onBlur={this.setInactive}>
                        <List>
                            {resultsHtml}
                        </List>
                    </Paper> */}
                </NoSsr>
            </div>            
        );
    }
}

SinusbotSearchClass.propTypes = {
    classes: PropTypes.object.isRequired,
};

const SinusbotSearch = withStyles(styles, {withTheme: true})(SinusbotSearchClass);

export { SinusbotSearch };
