import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isDefined } from '../../helpers';
import { Message } from './message.jsx';

const styles = theme => ({
    overlay: {
        textAlign: 'center',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        background: theme.palette.primary.main,
        opacity: .9,
        zIndex: 999
    },
});

class Overlay extends React.Component
{
    render() {
        let { classes, message, children } = this.props;

        // set visibility
        let visible = false;
        if (isDefined(message) && isDefined(message.visible)) {
            visible = message.visible;
        }

        let messageHTML;
        if (isDefined(message)) {
            messageHTML = (
                <Message title={message.title} content={message.content} />
            );
        } else {
            console.warn("No message?");
            return null;
        }

        return (
            <div className={classes.overlay} hidden={!visible} role="alert">
                {messageHTML}
                {children}
            </div>
        );
    }
}

Overlay.propTypes = {
    children: PropTypes.object,
    message: PropTypes.object.isRequired
};

const MainOverlay = withStyles(styles)(Overlay);

export {
    MainOverlay
};
