import React from 'react';
import { ListItem, Typography, ListItemIcon, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import { isDefined } from '../../helpers';
import { isFunction } from 'util';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import IconCheck from '@material-ui/icons/Check';
import IconFail from '@material-ui/icons/Error';


const styles = theme => ({
    progress: {
        // margin: theme.spacing(2),
    },
    jobText: {
        '& span, & p': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    }
});


class JobListComponent extends React.Component 
{
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    updateInputValue(evt) {
        this.setState({
            inputValue: evt.target.value
        });
    }

    handleAddDownload = (callback) => {
        let val = this.state.inputValue;
        if (!isDefined(val)) {
            this.props.enqueueSnackbar("Please enter a complete url", {
                variant: 'warning'
            });
            return;
        }

        this.props.enqueueSnackbar("Adding download job for " + val, {
            variant: 'info'
        });

        this.props.addJob(val, () => {
            if(isDefined(callback))
                callback();
            this.handleClose();
        });
    };

    handleAddStreaming = (callback) => {
        let val = this.state.inputValue;
        if(!isDefined(val)) {
            this.props.enqueueSnackbar("Please enter a complete url", {
                variant: 'warning'
            });
            return;
        }
        
        this.props.enqueueSnackbar("Adding stream job for " + val, {
            variant: 'info'
        });

        this.props.addStreaming(val, () => {
            if (isFunction(callback))
                callback();
            this.handleClose();
        });
    };

    render() {
        const { classes, jobs } = this.props;

        return (
            <div>
                <Button variant="outlined" color="default" onClick={this.handleClickOpen} fullWidth>
                    Add new Job
                </Button>

                <List>
                    {Array.isArray(jobs) && jobs.map((job, index) => {
                        return (
                            <Tooltip title={"["+job.status + "]: " + job.url} placement="right" key={index}>
                            <ListItem key={index} dense>

                                <ListItemIcon>
                                {{
                                    "success": (
                                        <IconCheck />
                                    ),
                                    "error": (
                                        <IconFail />
                                    ),
                                    "pending": (
                                        <CircularProgress className={classes.progress} />
                                    ),
                                    default: (
                                        <div></div>
                                    )
                                }[job.status]}
                                </ListItemIcon>

                                <ListItemText
                                    className={classes.jobText}
                                    primary={job.uuid}
                                    secondary={job.url}
                                />
                                {/* <ListItemSecondaryAction>
                                    <IconButton aria-label="Delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction> */}
                            </ListItem>
                            </Tooltip>
                        );
                    })}
                    {(!Array.isArray(jobs) || jobs.length <= 0) && (
                        <Typography>No job(s) found.</Typography>
                    )}
                </List>


                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Enter the stream or download url</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To command sinusbot to download and stream a track from youtube just enter the URL into the input field below.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="URL"
                            // type="email"
                            type="url"
                            onChange={evt => this.updateInputValue(evt)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="default">
                            Cancel
                        </Button>
                        <Button onClick={this.handleAddDownload} color="default">
                            Add Download Job
                        </Button>
                        <Button onClick={this.handleAddStreaming} color="default">
                            Add Streaming Job
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

JobListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

const JobList = withSnackbar(withStyles(styles)(JobListComponent));
export {
    JobList
}
