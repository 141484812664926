import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, List, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconRight from '@material-ui/icons/ArrowRight';
import IconUser from '@material-ui/icons/Accessibility';
// import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import { isDefined } from './helpers';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    listParent: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    client: {
        color: '#339bba',
        '& *': {
            color: '#339bba',
        }
    },
    collapse: {
        backgroundColor: 'rgba(0,0,0,.3)'
    }
});

class TeamspeakChannelListComponent extends React.Component 
{
    render() {
        const { classes, channels } = this.props;

        return (
            <List
                component="nav"
                className={classes.listParent}
            >
                {Array.isArray(channels) && channels.map((channel, index) => {
                    return(
                        <TeamspeakChannelListItem channel={channel} key={index} />
                    );
                })}

                {(!Array.isArray(channels) || channels.length <= 0) && (
                    <ListItem button>
                        <Typography>No channel(s) found.</Typography>
                    </ListItem>
                )}
            </List>
        );
    }
}
TeamspeakChannelListComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

class TeamspeakChannelListItemComponent extends React.Component {
    state = {
        open: false,
    };

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render() {
        const { channel, classes } = this.props;

        let hasUsers = false;
        if (Array.isArray(channel.clients) && channel.clients.length > 0)
            hasUsers = true;

        let hasChildren = false;
        if (Array.isArray(channel.children) && channel.children.length > 0)
            hasChildren = true;

        return (
            <div>
                <ListItem button={hasChildren || hasUsers} onClick={this.handleClick}>
                    {(hasChildren || hasUsers) && (
                        <ListItemIcon>
                            <div>
                                {hasChildren && (
                                    <IconRight />
                                )}
                                {hasUsers && (
                                    <IconUser className={classes.client} />
                                )}
                            </div>
                        </ListItemIcon>
                    )}
                    <ListItemText inset primary={channel.nick || channel.name} />
                </ListItem>

                {(hasChildren || hasUsers) && (
                    <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                        <List component="div" className={classes.collapse} disablePadding>
                            {hasChildren && channel.children.map((child, index) => {
                                return (
                                    <TeamspeakChannelListItem channel={child} key={index} />
                                );
                            })}
                            {hasUsers && channel.clients.map((child, index) => {
                                return (
                                    <TeamspeakClient client={child} key={index} />
                                );
                            })}
                        </List>
                    </Collapse>
                )}
            </div>
        )
    }
}
TeamspeakChannelListItemComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

class TeamspeakClientComponent extends React.Component {

    render() {
        const { client, classes } = this.props;

        return (
            <div className={classes.client}>
                <ListItem>
                    <ListItemIcon>
                        <IconUser />
                    </ListItemIcon>
                    <ListItemText inset primary={client.nick} />
                </ListItem>
            </div>
        )
    }
}
TeamspeakClientComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};


const ChannelList = withStyles(styles)(TeamspeakChannelListComponent);
const TeamspeakChannelListItem = withStyles(styles)(TeamspeakChannelListItemComponent);
const TeamspeakClient = withStyles(styles)(TeamspeakClientComponent);
export {
    ChannelList, TeamspeakChannelListItem, TeamspeakClient
};
