import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import BgTotal from './img/pop.gif';
import BgInner from './img/bg.png';
import SearchBar from './searchBar';
import Intro from './introText';
import Results from './resultList';
// import RecipeAdd from './recipeAdd';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const styles = theme => ({
    '@global': {
        body: {
            background: '#d2c6a5 url(' + BgTotal + ') repeat center top',
        }
    },
    root: {
        background: 'url(' + BgInner +') repeat center top',
        padding: theme.spacing(3)
    },
    inner: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
});

const version = "0.85";

const cookTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#880000',
            main: '#332200',
            dark: '#221100',
        },
        secondary: {
            light: '#770000',
            main: '#778811',
            dark: '#778811',
        },
    },
    text: {
        primary: "#FF0000",
        secondary: "#eee"
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Comic Sans, cursive'
    },
});



class KitchenMate extends React.Component {
    state = {
        recipes: [],
        ingredients: [],
    };

    componentDidMount() {
        this.fetchAllIngredients();
        this.fetchAllRecipes();
    }

    fetchAllIngredients = () => {
        fetch('/r3st/ingredients')
        .then(response => response.json())
        .then(data => this.setState({
            ingredients: data.map(ingredient => ({
                value: ingredient,
                label: ingredient,
            }))
        }))
        .catch((reason) => {
            this.props.enqueueSnackbar("Could not retrieve ingredients!", {
                variant: 'error'
            });
        });
    }

    fetchAllRecipes = () => {
        fetch('/r3st/recipes')
        .then(response => response.json())
        .then(data => this.setState({ recipes: data }))
        .catch((reason) => {
            this.props.enqueueSnackbar("Could not retrieve recipes!", {
                variant: 'error'
            });
        });
    }

    handleSearchbarUpdate = (value) => {
        // event.preventDefault();
        console.log("search bar value", value);

        var out = [];
        if (value !== null && value.length > 0) {
            for(var i = 0; i < value.length; i++) {
                out.push(value[i].label);
            }
        } else {
            this.fetchAllRecipes();
            return;
        }
        out = JSON.stringify(out);

        fetch('/r3st/recipesByIngredients/' + out)
            .then(response => response.json())
            .then(data => this.setState({ recipes: data }))
            .catch(reason => console.log(reason))
            ;
    }

    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={cookTheme}>
                <div className={classes.root} elevation={1}>
                    <Paper className={classes.inner} elevation={1}>
                        <Typography variant="h4" gutterBottom>KitchenMate {version}</Typography>
                        <Intro />
                        <SearchBar ingredients={this.state.ingredients} handleSearchbarUpdate={this.handleSearchbarUpdate} />
                    </Paper>
                    <Results items={this.state.recipes} />
                    {/* <RecipeAdd /> */}
                </div>
            </MuiThemeProvider>
        )
    }
}

KitchenMate.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default App;
export default withSnackbar(withStyles(styles)(KitchenMate));

