import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        padding: theme.spacing(8),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        height: 'calc(100vh - ' + theme.spacing(14) + 'px)',
        background: 'url(https://media.giphy.com/media/U3qYN8S0j3bpK/giphy.gif) no-repeat 100% / cover',
    },
    container: {
        maxWidth: 640,
        margin: '0 auto',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 'calc(50% - 100px)',
        bottom: 0
    }
});

const errorTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            light: 'yellow',
            main: '#555500',
            dark: 'red',
        },
        secondary: {
            light: 'green',
            main: '#227799',
            dark: 'purple',
        },

    },
    typography: {
        useNextVariants: true,
    },
});



class ErrorBoundaryClass extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    render() 
    {
        const { classes } = this.props;
        
        if (this.state.hasError) {
            return (
                <MuiThemeProvider theme={errorTheme}>
                    <Paper className={classes.root}>
                        <div className={classes.container}>
                            <Typography variant="h3" gutterBottom>Houston, we have a problem...</Typography>
                            <Typography variant="h4">You can simply reload the page and try again, but you could also try to contact the administrtor on this topic.</Typography>
                        </div>
                    </Paper>
                </MuiThemeProvider>
            );
        }

        return this.props.children;
    }
}

const ErrorBoundary = withStyles(styles)(ErrorBoundaryClass);
export { ErrorBoundary };
