import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Header(props) {
    return (
        <section className="IntroText">
            {/*<img src={logo} className="App-logo" alt="logo" />*/}
            <Typography variant="subtitle1" gutterBottom>
                Rezepte nach Zutaten finden
            </Typography>
            <Typography variant="subtitle2" component="p" gutterBottom>
                Klicke einfach mal in die Suchleiste unterhalb und wähle die Zutaten aus, die du im Rezept wiederfinden möchtest.<br/>
                Die Vorschläge der Suchleiste werden in Echtzeit aus den Zutaten im gesamten Rezepte-Vorrat ermittelt.
            </Typography>
            <Typography variant="subtitle2" component="p" gutterBottom>
                Die Möglichkeiten neue Rezepte hinzuzufügen oder eigene, bestehende Rezepte zu verändern wird es hier in Bälde geben.
            </Typography>
        </section>
    )
}
