import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Typography, Link, ListItem, ListItemIcon, ListItemText, Collapse, List, Divider, Button, Input, Grid } from '@material-ui/core';
import { SinusbotSearch } from './search-bot';
import JSONPretty from 'react-json-pretty';
// import CogsIcon from '@material-ui/icons/SettingsApplications';
import SearchIcon from '@material-ui/icons/Search';
import DebugIcon from '@material-ui/icons/TabletMacSharp';
import ImgLogo from './img/logo.png';
import { TrackInfoPlayerCard } from './player-controls';
import { isDefined } from '../../helpers';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ChannelList } from './teamspeak';
import { JobList } from './sinusbot-joblist';
import IconJobList from '@material-ui/icons/Assignment';
import IconTsList from '@material-ui/icons/SpeakerNotes';
import IconControls from '@material-ui/icons/VideogameAsset';

const styles = theme => ({
    root: {
        // padding: "0 " + theme.spacing(1) + "px",
    },
    item: {
        // marginBottom: theme.spacing(1),
        padding: theme.spacing(1)
        // backgroundColor: theme.palette.primary.light
    },
    section: {
        // marginBottom: theme.spacing(2),
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(1),
        // backgroundColor: '#CCC'
    },
    content: {
        padding: theme.spacing(1),
    },
    toolbar: theme.mixins.toolbar,
});

const slideoutTheme = createMuiTheme({
    palette: {
        type: 'dark',
        background: {
            default: 'red'
        },
        primary: {
            light: 'yellow',
            main: '#555500',
            dark: 'red',
        },
        secondary: {
            light: 'green',
            main: '#227799',
            dark: 'purple',
        },

    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Consolas'
    },
});

function SlideoutSectionsClass(props) 
{
    let { classes, synchronization, net, medium, syncManual, botPlay, botStop, botNext, botPrev, hasInstance, channels, jobs, botAddJob, botAddStreaming, botGetLastStreamed } = props;

    return (
        <MuiThemeProvider theme={slideoutTheme}>
            <div className={classes.root}>
                <Link href="https://forum.sinusbot.com/resources/syncwatch.204/" target="_blank" rel="noopener noreferrer">
                    <img src={ImgLogo} alt="made by vincolus" title="made by vincolus" />
                </Link>

                <SlideoutExpandable name={"Debug Info"} icon={<DebugIcon />} location={"?debug"}>
                    <SlideoutSection>
                        <div className={classes.section}>
                            <Button fullWidth onClick={botGetLastStreamed}>Fetch last streamed</Button>
                        </div>

                        <Divider variant="middle" />

                        <div className={classes.section}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Input type="text" placeholder="Sync Interval" value={synchronization.interval} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" onClick={syncManual}>Manual Sync</Button>
                                </Grid>
                            </Grid>
                        </div>

                        <Divider variant="middle" />

                        <div className={classes.section}>
                            <Typography component="p">Sync #{synchronization.count}. JSON:</Typography>
                            <JSONPretty json={medium} style={{ color: '#EEE' }}></JSONPretty>
                        </div>

                    </SlideoutSection>
                </SlideoutExpandable>

                <SlideoutExpandable name={"Instance Controls"} icon={<IconControls />} condition={hasInstance() && synchronization.active}>
                    <SlideoutSection>
                        <TrackInfoPlayerCard
                            medium={medium}
                            botPlay={botPlay}
                            botStop={botStop}
                            botNext={botNext}
                            botPrev={botPrev}
                        />
                    </SlideoutSection>
                </SlideoutExpandable>

                <SlideoutExpandable name={"Search Library"} icon={<SearchIcon />} condition={hasInstance()}>
                    <SlideoutSection>
                        <SinusbotSearch headers={net.headers} botPlay={botPlay} />
                    </SlideoutSection>
                </SlideoutExpandable>

                <SlideoutExpandable name={"Jobliste"} icon={<IconJobList />} condition={hasInstance() && synchronization.active}>
                    <SlideoutSection>
                        <JobList jobs={jobs} addJob={botAddJob} addStreaming={botAddStreaming} />
                    </SlideoutSection>
                </SlideoutExpandable>

                <SlideoutExpandable name={"TS3 Channels"} icon={<IconTsList />} condition={hasInstance() && synchronization.active}>
                    <SlideoutSection>
                        <ChannelList channels={channels} />
                    </SlideoutSection>
                </SlideoutExpandable>
        
            
            </div>
        </MuiThemeProvider>
    )
}

SlideoutSectionsClass.propTypes = {
    classes: PropTypes.object.isRequired,
    hasToken: PropTypes.func.isRequired,
    hasInstances: PropTypes.func.isRequired,
};

function SlideoutSectionClass(props) {
    const { classes, children, height, location } = props;

    if (isDefined(location)) {
        let url = window.location.href;
        if (!url.includes(location)) {
            return null;
        }
    }

    let styles = {};
    if (isDefined(height)) {
        styles = { maxHeight: height, overflowX: 'auto' };
    }

    return (
        <div className={classes.item} style={styles}>
            {children}            
        </div>
    );
}

class SlideoutExpandableClass extends React.Component {
    state = {
        open: true,
    };

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render() {
        const { classes, children, icon, name, location, condition } = this.props;

        if (isDefined(location)) {
            let url = window.location.href;
            if (!url.includes(location)) {
                return null;
            }
        }

        if (isDefined(condition))
            if (condition !== true) {
                console.log("condition is", condition);
                return null;
            }

        return (
            <List className={classes.section}>
                <ListItem button onClick={this.handleClick}>
                    {icon ? (
                        <ListItemIcon>{icon}</ListItemIcon>
                    ) : ''}
                    {name ? (
                        <ListItemText inset primary={name} />
                    ) : ''}
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children}
                    </List>
                </Collapse>
            </List>
        );
    }
}

const SlideoutExpandable = withStyles(styles, { withTheme: true })(SlideoutExpandableClass);
const SlideoutSections = withStyles(styles, { withTheme: true })(SlideoutSectionsClass);
const SlideoutSection = withStyles(styles)(SlideoutSectionClass);

export { SlideoutSection, SlideoutExpandable, SlideoutSections };
