import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import FriendsList from './friendslist';
// import MyResponsiveHeatMap from '../../charts/heatmap';
import MyResponsiveBubble from '../../charts/bubble';
import { withSnackbar } from 'notistack';
import { Typography, Divider, Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import moment from 'moment';
import { isDefined } from '../../../helpers';
import GamesList from './gameslist';

const styles = theme => ({
    root: {
        // padding: theme.spacing(2),
    },
    section: {
        margin: theme.spacing(2) + 'px 0',
        padding: theme.spacing(2),
    },
    profile: {
        margin: theme.spacing(2) + 'px 0',
        padding: theme.spacing(2),
    },
    fab: {
        margin: theme.spacing(1) + 'px ' + theme.spacing(1) + 'px ' + theme.spacing(1) + 'px 0',
    }
});

export const GetBubbleData = (sectionName, colorHSL, childOrLoc) => {
    let result = {
        "name": sectionName,
        "color": colorHSL,
    }
    if (typeof childOrLoc === "object")
        result["children"] = childOrLoc;
    else 
    if (typeof childOrLoc === "number")
        result["loc"] = childOrLoc;
    return result;
}


class GamesInCommon extends React.Component 
{
    state = {
        bubbleData: null
    }

    updateInputValue(evt) {
        this.setState({
            nameFriend: evt.target.value
        });
    }

    componentDidMount() {
        const { user } = this.props;
        const { games } = user;

        if(!isDefined(games))
            return;

        this.setState({
            bubbleData: GetBubbleData("Games in common", "hsl(254, 50%, 40%)", games)
        });
    }


    render() {
        const { 
            classes, 
            openId,
            user,
            handleFetchProfile, 
            handleRefreshFriends, 
            handleRefreshFriendsOfFriends, 
            handleRefreshGames, 
            handleRefreshProfilesOfFriends,
            ...other
        } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.section}>
                    <Grid container spacing={4}>

                        {/* <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="nameFriend"
                                label="Username"
                                // type="email"
                                // type="url"
                                onChange={evt => this.updateInputValue(evt)}
                                fullWidth
                            />
                        </Grid> */}

                        <Grid item xs={12} md={7}>
                            <Typography variant="h4">GamesInCommon <small>0.1</small></Typography>
                            <Typography variant="h6" gutterBottom>Welche Games haben du und deine Freunde gemeinsam?</Typography>

                            <Divider variant="fullWidth" />
                            <br />

                            <Typography variant="caption">Fertig ist es nicht - testen kann man es aber schon.</Typography>
                            <Typography variant="h5" gutterBottom>Rufe deine Daten manuell von Steam ab</Typography>
                            
                            <Typography paragraph>
                                Du kannst hiermit deine Daten von Steam abrufen und in die Datenbank von murnau.rocks eintragen lassen.<br/>
                                Klicke dazu einfach die Buttons von links nach rechts durch, je nachdem was du abrufen und in die DB übernehmen möchtest.
                            </Typography>
                            <Typography variant="body2" paragraph>Wenn du deine Daten in der Datenbank aktualisieren möchtest, klicke einfach wieder auf die Buttons.</Typography>

                            <Fab variant="extended" color="primary" onClick={handleFetchProfile} aria-label="Search" className={classes.fab}>
                                <NavigationIcon className={classes.extendedIcon} />
                                Mein Profil
                            </Fab>

                            <Fab variant="extended" color="primary" onClick={handleRefreshFriends} aria-label="Freunde aktualisiern" className={classes.fab}>
                                <NavigationIcon className={classes.extendedIcon} />
                                Freunde
                            </Fab>

                            <Fab variant="extended" color="primary" onClick={handleRefreshProfilesOfFriends} aria-label="Search" className={classes.fab}>
                                <NavigationIcon className={classes.extendedIcon} />
                                Freundes-Profile
                            </Fab>

                            <Fab variant="extended" color="primary" onClick={handleRefreshGames} aria-label="Search" className={classes.fab}>
                                <NavigationIcon className={classes.extendedIcon} />
                                Freundes-Games
                            </Fab>

                            <Fab variant="extended" color="primary" onClick={handleRefreshFriendsOfFriends} aria-label="Freundes-Freunde aktualisieren" className={classes.fab}>
                                <NavigationIcon className={classes.extendedIcon} />
                                Freundes-Freunde
                            </Fab>

                        </Grid>

                        <Grid item xs={12} md={5}>
                            <Typography variant="h5">Mein Profil</Typography>
                            <Card className={classes.profile}>
                                <Typography variant="body1">Deine 64bit SteamID ist: {openId}</Typography>

                                {user.summary && (
                                    <div>
                                        <Divider variant="fullWidth" />
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <Typography>Name: {user.summary.personaname}</Typography>
                                                <Typography>SteamIDx64: {user.summary.steamid}</Typography>
                                                {/* <Typography>{user.summary.communityvisibilitystate}</Typography> */}
                                                {/* <Typography>{user.summary.profilestate}</Typography> */}
                                                <Typography>Logoff: {JSON.stringify(moment.unix(user.summary.lastlogoff))}</Typography>
                                                <Typography>URL: {user.summary.profileurl}</Typography>
                                                {/* <Typography>{user.summary.personastate}</Typography> */}
                                                <Typography>ClanID: {user.summary.primaryclanid}</Typography>
                                                <Typography>Created: {JSON.stringify(moment.unix(user.summary.timecreated))}</Typography>
                                                {/* <Typography>{user.summary.personastateflags}</Typography> */}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {/* avatar, avatarmedium, avatarfull */}
                                                <img src={user.summary.avatarfull} alt="" />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                            </Card>

                        </Grid>

                        {isDefined(this.state.bubbleData) && (
                            <div style={{height: '640px'}}>
                                {/* <MyResponsiveHeatMap data={heatData} /> */}
                                <MyResponsiveBubble root={this.state.bubbleData} />
                            </div>
                        )}
                    </Grid>

                    <Grid container spacing={5}>
                        <Grid item xs={12} md={5}>
                            <Typography variant="h5">Meine Games</Typography>
                            <Card className={classes.profile}>
                                <GamesList user={this.props.user} {...other} />
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <Typography variant="h5">Meine Freunde</Typography>
                            <Card className={classes.profile}>
                                <FriendsList user={this.props.user} {...other} />
                            </Card>
                        </Grid>
                    </Grid>

                </div>
            </div>
        )
    }
}

GamesInCommon.propTypes = {
    classes: PropTypes.object.isRequired,
};

//export default App;
export default withSnackbar(withStyles(styles)(GamesInCommon));
