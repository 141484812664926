import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { isDefined } from '../../helpers';
// import { API_URLS } from '../../db/schemasSteam';

//import RainEffect from 'rain-effect';

const styles = theme => ({
    '@global': {
        body: {
            background: '#1B2838',
        }
    },
    root: {
        // padding: theme.spacing(2),
    },
    section: {
        margin: theme.spacing(2) + 'px 0',
        padding: theme.spacing(2),
    },
});

const debugTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            light: '#EEEEEE',
            main: '#1B2838',
            dark: '#111111',
        },
        secondary: {
            light: '#EEEEEE',
            main: '#4E5B69',
            dark: '#778811',
        },
    },
    text: {
        primary: "#ffffff",
        secondary: "#eee"
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Consolas'
    },
});

const version = "0.1";


class DebugTool extends React.Component 
{
    state = { };

    handleFakeLogin = () => {
        const { handleRootState } = this.props;

        console.log("fetching...");

        fetch("/r3st/sample/user")
        .then(response => response.json())
        .then((res, err) => {
            if(isDefined(err)) {
                console.error(err);
                this.props.enqueueSnackbar("Could not retrieve random user data! "+err, {
                    variant: 'error'
                });
                return;
            }

            let sampleUser = res;
            console.debug("user", sampleUser);
            
            handleRootState({
                user: sampleUser, session: {
                    openId: sampleUser.openId
                }
            });
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={debugTheme}>
                <div className={classes.root}>
                    <div className={classes.section}>
                        <Typography variant="h4" gutterBottom>DebugTool {version}</Typography>

                        <Button variant="contained" color="primary" type="submit" onClick={this.handleFakeLogin}>Impersonate</Button>

                    </div>
                </div>
            </MuiThemeProvider>
        )
    }
}

DebugTool.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default App;
export default withSnackbar(withStyles(styles)(DebugTool));

