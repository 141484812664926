import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import moment from 'moment';
import { isDefined } from '../../../helpers';

const styles = theme => ({
    root: {
        // padding: theme.spacing(2),
    },
    section: {
        // margin: theme.spacing(2) + 'px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    friend: {
        //margin: '0 ' + theme.spacing(2) + 'px ' + theme.spacing(2) + 'px 0 0',
        padding: theme.spacing(1) + 'px',
        backgroundColor: '#1F1F22',
        margin: theme.spacing(1) + 'px 0',
        border: '1px solid #111122',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '32%'
        },
        // [theme.breakpoints.up('md')]: {
        //     width: '75px'
        // },
        // [theme.breakpoints.up('lg')]: {
        //     width: '12%'
        // },
        // [theme.breakpoints.up('xl')]: {
        //     width: '8%'
        // },
    },
    profile: {
        padding: theme.spacing(2)
    },
    fab: {
        
    }
});

const Friend = (props) => {
    const { classes, friend } = props;
    // let relation = (friend.relationship === 1 ? 'Bekannter' : 'Freund');

    return (
        <div className={classes.friend}>
            <Typography variant="subtitle1">{(friend.summary && friend.summary.personaname) || friend.steamid}</Typography>
            <Typography variant="caption">{(friend.friend_since && JSON.stringify(moment.unix(friend.friend_since)))}</Typography>
            {/* <Typography variant="body1">{relation}</Typography> */}
            {friend.summary && (
                <a href={friend.summary.profileurl} target="_blank" rel="noopener noreferrer">
                    <img src={friend.summary.avatarmedium} alt="" />
                </a>
            )}
        </div>
    )
}

const StyledFriend = withStyles(styles)(Friend);

class FriendsList extends React.Component {
    render() {
        const { classes, user } = this.props;
        const { friends } = user;

        if (!isDefined(friends))
            return null;

        return (
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>Du hast {friends.length} Freunde</Typography>

                <Divider variant="middle" />

                <div className={classes.section}>
                    {Array.isArray(friends) && friends.map((friendObject, i) => <StyledFriend key={i} friend={friendObject} />)}
                </div>
            </div>
        )
    }
}

FriendsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

//export default App;
export default withStyles(styles)(FriendsList);
