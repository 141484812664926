import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FormLogin } from './form-login';
import FormInstanceSelection from './form-instance-selection';
import { isDefined } from '../../helpers';

export function getBotNotRunningMessage() {
    return {
        title: 'No bot',
        content: (
            <div>
                <Typography component="p">It seems that sinusbot is not running.<br />Tell the admin to turn it on!</Typography>
            </div>
        ),
        visible: true
    };
}

export function getFormLoginMessageJSX(props) {
    let { user, handleUserStateChange, handleFormLogin, setMessage, fetchInstanceInfos, onStore, handleStepperNext, handleStepperFail } = props;

    return (
        <div>
            <Typography component="p">visit our teamspeak and pm <code>!sw</code><br />to the bot to get an authorized login url.</Typography>
            <FormLogin
                user={user}
                handleUserStateChange={handleUserStateChange}
                handleStepperNext={handleStepperNext}
                handleStepperFail={handleStepperFail}
                handleFormLogin={handleFormLogin}
                show={setMessage}
                fetchInstanceInfos={fetchInstanceInfos}
                onStore={onStore}
            />
        </div>
    );
}

export function getLoginMessage(props) {
    console.debug("< Getting login message with props", props);
    return {
        title: 'No token',
        content: getFormLoginMessageJSX(props),
        visible: true
    };
}

export function getInstancesMessage(props) {
    let { user, chooseInstance, handleUserStateChange, onStore} = props;

    let inst = user.instance;
    if (!isDefined(inst))
        inst = '';

    return {
        title: 'Which one?', 
        content: (
            <div>
                <Typography component="p">Please select a running instance</Typography>
                    <FormInstanceSelection
                        instance={inst}
                        instances={user.instances}
                        chooseInstance={chooseInstance}
                        handleUserStateChange={handleUserStateChange}
                        onStore={onStore}
                    />
            </div>
        ),
        visible: true
    };
}

export function getStatusCodes(callback, props) {
    return {
        "401": function () {
            callback(getLoginMessage(props));
        },
        "403": function () {
            callback({ title: '#403 Forbidden', content: <Typography component="p">Sorry, but you do not have permission for this</Typography> });
        },
        "404": function () {
            callback({ title: '#404 Not found', content: <Typography component="p">The requested URL could not be found</Typography> });
        }
    };
}

