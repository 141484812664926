import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { FormControl, Button, CardMedia } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
// import PauseIcon from '@material-ui/icons/Pause';
import { Typography } from '@material-ui/core';
import { isDefined } from '../../helpers';

const styles = theme => ({
    formControl: {
        display: 'flex',
        flexDirection: 'row'
    },
    playerControls: {
        width: '50%'
    },

    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
});

class PlayerControlsClass extends React.Component {
    render() {
        let { classes, botPlay, botStop } = this.props;

        return (
            <FormControl className={classes.formControl}>
                <Button className={classes.playerControls} onClick={botPlay}>
                    <PlayIcon />
                </Button>
                <Button className={classes.playerControls} onClick={botStop}>
                    <StopIcon />
                </Button>
            </FormControl>
        )
    }
}

PlayerControlsClass.propTypes = {
    classes: PropTypes.object.isRequired,
};


function TrackInfoPlayerCardClass(props) {
    const { classes, theme } = props;
    const { disabled, medium, botPlay, botStop, botNext, botPrev } = props;
    const { playing, artist, title, thumbnail, uuid, playlistTrack } = medium;

    return (
        <Card className={classes.card + (disabled ? ' disabled' : '') }>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {artist}
                    </Typography>
                </CardContent>
                <div className={classes.controls}>
                    <IconButton aria-label="Previous" onClick={() => { botPrev() }} disabled={!isDefined(playlistTrack)}>
                        {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                    </IconButton>
                    {playing === true ? (
                        <div>
                            <IconButton aria-label="Stop" onClick={() => { botStop() }}>
                                <StopIcon className={classes.playIcon} />
                            </IconButton>
                        </div>
                    ) : (
                        <IconButton aria-label="Play" onClick={() => { botPlay(!playing)}} disabled={!isDefined(uuid)}>
                            <PlayArrowIcon className={classes.playIcon} />
                        </IconButton>
                    )}
                    {/* <IconButton aria-label="Pause" onClick={() => { botPlay(!playing) }}>
                        <PauseIcon className={classes.playIcon} />
                    </IconButton> */}

                    <IconButton aria-label="Next" onClick={() => { botNext() }} disabled={!isDefined(playlistTrack)}>
                        {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                    </IconButton>
                </div>
            </div>
            
            {isDefined(thumbnail) && (
                <CardMedia
                    className={classes.cover}
                    image={thumbnail}
                    title={title}
                />
            )}
        </Card>
    );
}

TrackInfoPlayerCardClass.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    isPlaying: PropTypes.bool
};

const TrackInfoPlayerCard = withStyles(styles, { withTheme: true })(TrackInfoPlayerCardClass);
const PlayerControls = withStyles(styles, { withTheme: true })(PlayerControlsClass);

export {
    TrackInfoPlayerCard, PlayerControls
};
