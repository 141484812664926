import { isDefined } from "../../helpers";

export function getTabValue(location, i) {
    let pieces = location.pathname.split('/');

    if(!isDefined(i))
        i = pieces.length - 1;

    if (pieces.length > i)
        return pieces[i];
    return "";
}
